
import React, { useState, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import ValidationFeedback from '../../components/validationFeedback';
import CommonButton from '../../components/commonButton';
import { Container, Image, Form, Toast, ToastContainer } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../modules/Auth/authSlice";
import { loginSchema } from "../../schema/validationSchemas";
import { ViewOffSlashIcon, EyeIcon, CheckmarkBadge04Icon } from "../../assets/icons";
import logoBlack from "../../assets/images/logo/logo-dark.png";
import "./login.css";
// import { IconData } from "../../../constants/pages/auth/login";

export default function LogIn() {
  const [showToast, setShowToast] = useState(false);
  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('toast-success');
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await dispatch(loginUser(values)).unwrap();
      if (result && result.success) {
        localStorage.setItem('auth-token', result.token);
        setToastMessage(result.message || 'Logged In successfully!');
        setToastVariant('toast-success');
        navigate('/');
      } else {
        setToastMessage(result.message || 'Failed to login!');
        setToastVariant('toast-danger');
        // navigate('/');
      }
    } catch (err) {
      const errorMessage = err?.message || 'Failed to login!';
      setToastMessage(errorMessage);
      setToastVariant('toast-danger');
      // navigate('/');
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant('toast-danger');
      setShowToast(true);
    }

    if (status === 'loading') {
      setToastMessage('Logging in...');
      setToastVariant('toast-warning');
      setShowToast(true);
    }
  }, [status, error]);
  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid="xxl" className="mx-auto px-4">
        <div className="d-flex align-items-center justify-content-center py-4  login-content">
          <div className="position-relative w-100 py-4 login-sub-content">
            <div className="bg-white rounded-2 p-5 common-shadow login-card">
              <h1 className="fs-5 d-flex align-content-center justify-content-center mb-4">
                <Link to="https://mep.in/" className=" text-decoration-none  d-inline-block">
                  <Image src={logoBlack} alt="Site-Logo" />
                </Link>
              </h1>
              <div className="mb-4 welcome-content">
                <h4 className="mb-1 fw-medium">Welcome to The Cutting Story! 👋</h4>
                <p>Please sign-in to your account and start the adventure</p>
              </div>
              <Formik initialValues={{ email: '', password: '' }}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
              >

                {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (<Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <CommonFormLabel title="Email or Username" />
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter your email or username"
                      onChange={handleChange}
                      className={`shadow-none ${touched.email && errors.email ? 'error' : 'correct'}`}
                    />
                    {touched.email && errors.email ? (
                      <ValidationFeedback title={errors.email} />
                    ) : null}
                  </div>
                  <div className="mb-4">
                    <CommonFormLabel title="Password" />
                    <div className="position-relative">
                      <Input
                        id="password"
                        name="password"
                        // type="password"
                        type={`${showEyeIcon ? 'text' : 'password'}`}
                        placeholder="············"
                        onChange={handleChange}
                        className={`shadow-none ${touched.password && errors.password ? 'error' : 'correct'}`}
                      />
                      <div className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer">
                        { 
                          (showEyeIcon === false) ?
                          <ViewOffSlashIcon width={20} height={20} color="#444050" strokeWidth="1.5" className={touched.password && errors.password ? 'error' : 'correct'} onClick={() => setShowEyeIcon(true)} />
                            :
                          <EyeIcon width={20} height={20} color="#444050" strokeWidth="1.5" className={`${touched.password && errors.password ? 'error' : 'correct'}`} onClick={() => setShowEyeIcon(false)} />
                        }
                      </div>
                    </div>
                    {touched.password && errors.password ? (
                      <ValidationFeedback title={errors.password} />
                    ) : null}
                  </div>
                  <div className="d-flex align-items-center justify-content-end forget-link">
                    <Link to="/forgot-password" className="text-decoration-none">Forgot Password?</Link>
                  </div>
                  <CommonButton title="Login" type="submit" disabled={isSubmitting} className="w-100 btn-primary primary-shadow" />

                  {/* <div className="mb-4 welcome-content d-flex align-items-center justify-content-center gap-2 mt-4">
                    <p>New on our platform?</p>
                    <div className="register-link">
                      <Link to="/register" className="text-decoration-none register-link">Create an account</Link>
                    </div>
                  </div> */}
                  {/* <div className="white-space-nowrap d-block overflow-hidden text-center my-4">
                    <div className="position-relative d-inline-block px-3 divider-text">or</div>
                  </div> */}
                  {/* <div className="d-flex align-items-center justify-content-center gap-2">
                    {
                      IconData.map((item, i) => {
                        return (<Link to={item.link} key={i} className={`rounded-circle d-flex align-items-center justify-content-center form-icon ${item.className}`}>{item.icon}</Link>)
                      })
                    }

                  </div> */}
                </Form>)}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </>)
}