import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import TextArea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import SimpleDropdown from "../../components/simpleDropdown";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { Modal, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { updateCategory, getCategoryDetails, getCategories, getAllCategories } from "../../modules/Category/categorySlice";
import { categoryUpdateSchema } from "../../schema/validationSchemas";
import { Cancel01Icon } from "../../assets/icons";
import Editor from "../../components/commonEditor";

export default function Edit({
  show,
  handleClose,
  categoryId,
  currentPage,
  searchTerm,
  limit,
}) {
  const [category, setCategory] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [attributes, setAttributes] = useState([{ id: 1, name: '', values: [''] }]);
  const dispatch = useDispatch();

  const token = localStorage.getItem("auth-token");

  const resetAttributes = () => {
    setAttributes([{ id: 1, name: '', values: [''] }]);
  };

  const fetchCategories = useCallback(async () => {
    try {
      const payload = {
        "category_id" : categoryId
      }
      await dispatch(getAllCategories(payload)).then((res) => {
        if (res.payload.success) {
          const formattedData = res.payload.data.map((item) => ({
            value: item.id,
            label: item.category_name,
          }));
          setCategoryOptions(formattedData);
        } else {
          setCategoryOptions([]);
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, [dispatch, categoryId]);

  const fetchCategoryDetails = useCallback(async () => {
    try {
      const result = await dispatch(getCategoryDetails(categoryId));
      const categoryData = result.payload.data;

      const mappedAttributes = categoryData.attribute_groups.map((group, index) => ({
        id: group.id,
        name: group.attribute_group_name,
        values: group.attribute_groups_values.map(value => value.attribute_group_value),
      }));

      setCategory(result.payload.data);
      setAttributes(mappedAttributes);
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    setCategory(null);
    if (categoryId && show) {
      fetchCategories();
      fetchCategoryDetails();
    }
  }, [categoryId, show, fetchCategories, fetchCategoryDetails]);

  const handleModalClose = useCallback(() => {
    setCategory(null);
    resetAttributes();
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);

    const dataToSubmit = {
      ...values,
      id: categoryId,
      _method: 'PUT',
      attribute_groups: attributes.map((attribute) => ({
        name: attribute.name,
        values: attribute.values.filter(value => value !== ''),
      })),
    };

    const formData = new FormData();

    Object.entries(dataToSubmit).forEach(([key, value]) => {
      if (key === "attribute_groups") {
        formData.append(key, JSON.stringify(value)); // Convert to JSON string for backend
      } else {
        formData.append(key, value);
      }
    });

    await dispatch(updateCategory(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          setCategory(null);
          handleClose();
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            token,
          };
          dispatch(getCategories({ payload }));
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update category!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  // Functions to handle adding/removing attributes and values
  const addAttribute = () => {
    setAttributes([...attributes, { id: attributes.length + 1, name: '', values: [''] }]);
  };

  const removeAttribute = (attributeId) => {
    setAttributes(attributes.filter(attr => attr.id !== attributeId));
  };

  const addAttributeValue = (attributeId) => {
    const updatedAttributes = attributes.map(attr =>
      attr.id === attributeId ? { ...attr, values: [...attr.values, ''] } : attr
    );
    setAttributes(updatedAttributes);
  };

  const removeAttributeValue = (attributeId, valueIndex) => {
    const updatedAttributes = attributes.map(attr => {
      if (attr.id === attributeId) {
        const updatedValues = [...attr.values];
        updatedValues.splice(valueIndex, 1);
        return { ...attr, values: updatedValues };
      }
      return attr;
    });
    setAttributes(updatedAttributes);
  };

  const handleAttributeChange = (attributeId, name, index = null) => (e) => {
    const updatedAttributes = attributes.map(attr => {
      if (attr.id === attributeId) {
        if (name === 'name') {
          return { ...attr, name: e.target.value };
        } else if (name === 'values' && index !== null) {
          const updatedValues = [...attr.values];
          updatedValues[index] = e.target.value;
          return { ...attr, values: updatedValues };
        }
      }
      return attr;
    });
    setAttributes(updatedAttributes);
  };


  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        centered
        size="lg"
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Category</h4>
            <p>Edit existing category</p>
          </div>

          {/* No need to fetch category details, pre-fill the form with `data` */}
          {category && (
            <Formik
              initialValues={{
                parent_category_id: category?.parent_category_id || "",
                category_name: category.category_name || "",
                page_title: category.page_title || "",
                page_sub_title: category.page_sub_title || "",
                category_video_link: category.category_video_link || "",
                category_image: "",
                image_alt: category.image_alt || "",
                category_banner_image: "",
                brochure_file: "",
                category_description: category.category_description || "",
                meta_title: category.meta_title || "",
                meta_description: category.meta_description || "",
                meta_keyword: category.meta_keyword || "",
                default_category_description: category.default_category_description || "",
              }}
              validationSchema={categoryUpdateSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleChange, handleSubmit, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                  {/* <div className="mb-4"> */}
                  <Row>
                    <Col md={6} className="">
                      <CommonFormLabel htmlFor="category_name" title="Name" />
                      <Input
                        id="category_name"
                        name="category_name"
                        type="text"
                        placeholder="Enter Category Name"
                        onChange={(e) => {
                          const categoryName = e.target.value;
                          setFieldValue("category_name", categoryName);
                          setFieldValue("image_alt", categoryName);
                         
                          // Only update the image_alt if the user has not manually edited it
                          // if (!touched.image_alt || values.image_alt === values.category_name) {
                          //   setFieldValue("image_alt", categoryName);
                          // }
                        }}
                        value={values.category_name}
                        className={`shadow-none ${errors?.category_name && touched.category_name ? 'error' : 'correct'}`}
                      />
                      {touched.category_name && errors?.category_name && (
                        <ValidationFeedback title={errors?.category_name} />
                      )}
                    </Col>

                    <Col md={6} className="">
                      <SimpleDropdown
                        label="Parent Category (Optional)"
                        name="parent_category_id"
                        value={values.parent_category_id}
                        onChange={(selectedOption) => setFieldValue("parent_category_id", selectedOption.value)}
                        options={categoryOptions}
                        placeholder="Select a Parent Category"
                        error={errors?.parent_category_id}
                        touched={touched.parent_category_id}
                        className={'mb-4'}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} className="mb-4">
                      <CommonFormLabel htmlFor="page_title" title="Page Title" />
                      <Input
                        id="page_title"
                        name="page_title"
                        type="text"
                        placeholder="Enter Page Title"
                        onChange={handleChange}
                        value={values.page_title}
                        className={`shadow-none ${errors?.page_title && touched.page_title ? 'error' : 'correct'}`}
                      />
                      {touched.page_title && errors?.page_title && (
                        <ValidationFeedback title={errors?.page_title} />
                      )}
                    </Col>

                    <Col md={6} className="mb-4">
                      <CommonFormLabel htmlFor="page_sub_title" title="Page Sub Title" />
                      <Input
                        id="page_sub_title"
                        name="page_sub_title"
                        type="text"
                        placeholder="Enter Page Sub Title"
                        onChange={handleChange}
                        value={values.page_sub_title}
                        className={`shadow-none ${errors?.page_sub_title && touched.page_sub_title ? 'error' : 'correct'}`}
                      />
                      {touched.page_sub_title && errors?.page_sub_title && (
                        <ValidationFeedback title={errors?.page_sub_title} />
                      )}
                    </Col>
                    
                  </Row>

                  <div  className="mb-4">
                    <CommonFormLabel htmlFor="category_video_link" title="Video Link" />
                    <Input
                      id="category_video_link"
                      name="category_video_link"
                      type="text"
                      placeholder="Enter Video Link"
                      onChange={handleChange}
                      value={values.category_video_link}
                      className={`shadow-none ${errors?.category_video_link && touched.category_video_link ? 'error' : 'correct'}`}
                    />
                    {touched.category_video_link && errors?.category_video_link && (
                      <ValidationFeedback title={errors?.category_video_link} />
                    )}
                  </div>

                  <div className="mb-4">
                    <CommonFormLabel htmlFor="category_description" title="Category Description" />
                    <Editor
                      value={values.category_description}
                      onChange={(content) => setFieldValue("category_description", content)}
                    />
                    {touched.category_description && errors?.category_description && (
                      <ValidationFeedback title={errors?.category_description} />
                    )}
                  </div>

                  {/* Default Category Description */}
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="default_category_description" title="Dynamic Description" />
                    <Editor
                      value={values.default_category_description}
                      onChange={(content) => setFieldValue("default_category_description", content)} 
                    />
                    {touched.default_category_description && errors?.default_category_description && (
                      <ValidationFeedback title={errors?.default_category_description} />
                    )}
                  </div>

                  {/* Meta Title */}
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                    <Input
                      id="meta_title"
                      name="meta_title"
                      type="text"
                      placeholder="Enter Meta Title"
                      onChange={handleChange}
                      value={values.meta_title}
                      className={`shadow-none ${errors?.meta_title && touched.meta_title ? 'error' : 'correct'}`}
                    />
                    {touched.meta_title && errors?.meta_title && (
                      <ValidationFeedback title={errors?.meta_title} />
                    )}
                  </div>

                  {/* Meta Description */}
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="meta_description" title="Meta Description" />
                    <TextArea
                      id="meta_description"
                      name="meta_description"
                      placeholder="Enter Meta Description"
                      value={values.meta_description}
                      onChange={handleChange}
                      className={`shadow-none ${errors?.meta_description && touched.meta_description ? 'error' : 'correct'}`}
                      rows={4}
                    />
                    {touched.meta_description && errors?.meta_description && (
                      <ValidationFeedback title={errors?.meta_description} />
                    )}
                  </div>

                  {/* Meta Keywords */}
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" />
                    <TextArea
                      id="meta_keyword"
                      name="meta_keyword"
                      placeholder="Enter Meta Keywords"
                      onChange={handleChange}
                      value={values.meta_keyword}
                      className={`shadow-none ${errors?.meta_keyword && touched.meta_keyword ? 'error' : 'correct'}`}
                    />
                    {touched.meta_keyword && errors?.meta_keyword && (
                      <ValidationFeedback title={errors?.meta_keyword} />
                    )}
                  </div>

                  <Row>
                    <Col md={7} className="mb-4">
                      <CommonFormLabel htmlFor="category_image" title="Upload New Image" />
                      <FileInput
                        name="category_image"
                        onChange={(file) => setFieldValue("category_image", file)}
                        error={errors?.category_image}
                        touched={touched.category_image}
                      />
                      {touched.category_image && errors?.category_image && (
                        <ValidationFeedback title={errors?.category_image} />
                      )}
                    </Col>

                    <Col md={5} className="mb-4">
                      <CommonFormLabel htmlFor="image_alt" title="Image Alt" />
                      <Input
                        id="image_alt"
                        name="image_alt"
                        type="text"
                        placeholder="Enter Image Alt"
                        // onChange={handleChange}
                        onChange={(e) => {
                          setFieldValue("image_alt", e.target.value);
                        }}
                        value={values.image_alt}
                        className={`shadow-none ${errors?.image_alt && touched.image_alt ? 'error' : 'correct'}`}
                      />
                      {touched.image_alt && errors?.image_alt && (
                        <ValidationFeedback title={errors?.image_alt} />
                      )}
                    </Col>
                  </Row>

                  <div className="mb-4">
                    <CommonFormLabel htmlFor="category_banner_image" title="Upload New Banner Image" />
                    <FileInput
                      name="category_banner_image"
                      onChange={(file) => setFieldValue("category_banner_image", file)}
                      error={errors?.category_banner_image}
                      touched={touched.category_banner_image}
                    />
                    {touched.category_banner_image && errors?.category_banner_image && (
                      <ValidationFeedback title={errors?.category_banner_image} />
                    )}
                  </div>

                  <div className="mb-4">
                    <CommonFormLabel htmlFor="brochure_file" title="Upload Brochure" />
                    <FileInput
                      name="brochure_file"
                      onChange={(file) => setFieldValue("brochure_file", file)}
                      error={errors?.brochure_file}
                      touched={touched.brochure_file}
                    />
                    {touched.brochure_file && errors?.brochure_file && (
                      <ValidationFeedback title={errors?.brochure_file} />
                    )}
                  </div>

                  <hr className="my-5" />
                  <Row>
                    <Col md={6} className="">
                  <CommonFormLabel htmlFor="old_category_image" title="Uploaded Image" />
                    <div className="mt-2">
                        <img src={category.category_image} alt="Category" className="" height={"100px"} width={"100px"} />
                      </div>
                    </Col>
                    <Col md={6} className="">
                    <CommonFormLabel htmlFor="old_category_image" title="Uploaded Banner Image" />
                      <div className="mt-2">
                        <img src={category.category_banner_image} alt="Category" className="" height={"100px"} width={"100px"} />
                      </div>
                    </Col>
                  </Row>

                  <hr className="my-5" />
                  <Row>
                    <Col md={6} className="">
                      <CommonFormLabel htmlFor="brochure_file" title="Uploaded Brochure" />
                      <div className="mt-2">
                        <a href={category.brochure_file} target="_blank" rel="noopener noreferrer">
                          <p>{category.brochure_file}</p>
                        </a>
                      </div>
                    </Col>
                  </Row>


                  <hr className="my-5" />
                {/* {category && category.parent_category_id !== null && ( */}
                  <div className="mb-4 text-center common-popup-heading">
                    <h4 className="text-capitalize mb-2 fw-medium">Category Attributes</h4>
                  </div>
                {/* )} */}
                {/* {category && category.parent_category_id === null && ( */}

                  <div className="mb-4">
                    {attributes.map((attribute, index) => (
                      <div key={attribute.id} className="mb-4">
                        <CommonFormLabel htmlFor={`attributes ${index + 1}`} title={`Attribute ${index + 1}`} />
                        <Input
                          type="text"
                          placeholder={`Attribute ${index + 1} Name`}
                          value={attribute.name}
                          onChange={handleAttributeChange(attribute.id, 'name')}
                          required={true}
                        />
                        <div className="mt-2">
                          {attribute.values.map((value, valueIndex) => (
                            <div key={valueIndex} className="d-flex align-items-center">
                              <Input
                                type="text"
                                placeholder={`Value ${valueIndex + 1}`}
                                value={value}
                                onChange={handleAttributeChange(attribute.id, 'values', valueIndex)}
                                className="me-5"
                                required={true}
                              />
                              <button
                                type="button"
                                className="btn btn-danger ml-2"
                                onClick={() => removeAttributeValue(attribute.id, valueIndex)}
                              >
                                Remove
                              </button>
                            </div>
                          ))}
                          <CommonButton 
                            title="Add Value" 
                            type="button"
                            onClick={() => addAttributeValue(attribute.id)} 
                            disabled={isSubmitting} 
                            className="btn-primary primary-shadow mt-2" 
                          />
                          <button
                            type="button"
                            className="btn btn-danger mt-2 ms-2"
                            onClick={() => removeAttribute(attribute.id)}
                          >
                            Remove Attribute
                          </button>
                        </div>
                        <hr className="my-5" />
                      </div>
                    ))}
                    <CommonButton title="Add Attribute" type="button" onClick={addAttribute} disabled={isSubmitting} className="btn-primary primary-shadow" />
                  </div>
                {/* )} */}

                  <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                    <CommonButton title={isSubmitting ? "Updating..." : "Update"} type="submit" disabled={isSubmitting} className="btn-primary primary-shadow" />
                    <CommonButton title="Cancel" onClick={handleModalClose} className="btn-muted" />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
