import React, { useEffect, useState, useCallback } from "react";
import { Col, Modal, Row, Toast } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getProductDetails } from "../../modules/Product/productSlice";
import { Cancel01Icon } from "../../assets/icons";

export default function ProductView({
  show,
  handleClose,
  productId,
}) {
  const [product, setProduct] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const dispatch = useDispatch();

  const fetchProduct = useCallback(async () => {
    try {
      const result = await dispatch(getProductDetails(productId));
      setProduct(result.payload.data);
    } catch (error) {
      setSnackbarMessage("Failed to load product details!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    }
  }, [dispatch, productId]);

  useEffect(() => {
    if (productId && show) {
      fetchProduct();
    }
  }, [productId, show, fetchProduct]);

  const handleModalClose = useCallback(() => {
    setProduct(null);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">View Product</h4>
            <p>Product Details</p>
          </div>

          {product ? (
            <div >
              {/* Product Basic Details */}
              <Row>
                <Col md={6} className="mb-4">
                  <strong>Product Name :</strong>
                  <p>{product.product_name}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Product Code :</strong>
                  <p>{product.product_code}</p>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col md={6} className="mb-4">
                  <strong>Product Category :</strong>
                  <p>{product.category.category_name}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Product Sub Category :</strong>
                  <p>{product.subcategory.category_name}</p>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col md={6} className="mb-4">
                  <strong>Price(₹) :</strong>
                  <p>{product.price}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Selling Price(₹) :</strong>
                  <p>{product.selling_price}</p>
                </Col>
              </Row>

              <hr />

              <Row>
                <Col md={6} className="mb-4">
                  <strong>Quantity :</strong>
                  <p>{product.quantity}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Min Order Quantity :</strong>
                  <p>{product.min_order_quantity}</p>
                </Col>
              </Row>
              

              <hr />

              <div className="mb-4">
                <strong>Description :</strong>
                <div dangerouslySetInnerHTML={{ __html: product.description }} />
              </div>

              <hr />

              <div className="mb-4 text-center common-popup-heading my-2">
                <h5 className="text-capitalize mb-2 fw-medium">Meta Contents</h5>
              </div>

              {/* Product Meta Details */}
              <Row>
                <Col md={6} className="mb-4">
                  <strong>Meta Keywords :</strong>
                  <p>{product.meta_keyword}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Meta Title :</strong>
                  <p>{product.meta_title}</p>
                </Col>
              </Row>

              <hr />

              <div className="mb-4">
                <strong>Meta Description :</strong>
                <p>{product.meta_description}</p>
              </div>

              <hr />

              <div className="mb-4 text-center common-popup-heading mt-2">
                <h5 className="text-capitalize mb-2 fw-medium">Product Images</h5>
              </div>

              {/* Uploaded Product Images */}
              {product.uploaded_product_images && product.uploaded_product_images.length > 0 && (
                <div className="mb-4">
                  {/* <strong>Uploaded Images:</strong> */}
                  <Row className="d-flex flex-wrap align-items-end">
                    {product.uploaded_product_images.map((data) => (
                      <Col md={3} className="mt-2">
                        <img
                          key={data.id}
                          src={`${data.image_url}`}
                          alt={`Product data ${data.id}`}
                          style={{ maxWidth: "150px", maxHeight: "130PX" }}
                          // className="img-fluid"
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              )}

              <hr />

              <div className="mb-4 text-center common-popup-heading mt-2">
                <h5 className="text-capitalize mb-2 fw-medium">Product Attributes</h5>
              </div>

              {product.selected_product_attributes && product.selected_product_attributes.length > 0 && (
                <div className="mb-4">
                  <div>
                    <ul>
                      {Object.values(
                        product.selected_product_attributes.reduce((acc, attribute) => {
                          const groupId = attribute.attribute_group.id;
                          if (!acc[groupId]) {
                            acc[groupId] = {
                              groupName: attribute.attribute_group.attribute_group_name,
                              values: [],
                            };
                          }
                          acc[groupId].values.push(attribute.attribute_group_value.attribute_group_value);
                          return acc;
                        }, {})
                      ).map((group, index) => (
                        <li key={index} className="my-4">
                          <div key={index} className="d-flex align-items-center mb-3">
                            <h6>Attribute {index + 1}</h6>
                          </div>
                          <div key={index} className="d-flex align-items-center">
                            <h6>Attribute Name : </h6>
                            <span>&nbsp;{group.groupName}</span>
                          </div>
                          <div key={index} className="d-flex align-items-center">
                            <h6>Attribute Values : </h6>
                            <span>&nbsp;
                              {group.values.map((value, valueIndex) => (
                                <span key={valueIndex}>
                                  {valueIndex > 0 && ", "}
                                  {value}
                                </span>
                              ))}
                            </span>
                          </div>
                          <hr className="me-5" />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

            </div>
          ) : (
            <p>Loading product details...</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
