import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { Modal, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { updateGallery, getGalleryDetails, getGalleries } from "../../modules/Gallery/gallerySlice";
import { galleryUpdateSchema } from "../../schema/validationSchemas";
import { Cancel01Icon } from "../../assets/icons";

export default function Edit({
  show,
  handleClose,
  galleryId,
  currentPage,
  searchTerm,
  limit,
}) {
  const [gallery, setGallery] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const token = localStorage.getItem("auth-token");

  const fetchGallery = useCallback(async () => {
    try {
      const result = await dispatch(getGalleryDetails(galleryId));
      setGallery(result.payload.data);
    } catch (error) {
      console.error("Error fetching gallery:", error);
    }
  }, [dispatch, galleryId]);

  useEffect(() => {
    setGallery(null);
    if (galleryId && show) {
      fetchGallery();
    }
  }, [galleryId, show, fetchGallery]);

  const handleModalClose = useCallback(() => {
    setGallery(null);
    handleClose();
  }, [handleClose]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    const updatedValues = { ...values, id: galleryId };

    const formData = new FormData();
    formData.append('id', updatedValues.id);
    formData.append('_method', 'PUT');
    formData.append('gallery_title', updatedValues.gallery_title);
    formData.append('gallery_image', updatedValues.gallery_image);

    await dispatch(updateGallery(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          setGallery(null);
          handleClose();
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            token,
          };
          dispatch(getGalleries({ payload }));
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update gallery!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">Edit Gallery</h4>
            <p>Edit existing gallery</p>
          </div>

          {gallery && (
            <Formik
              initialValues={{
                gallery_title: gallery.gallery_title || "",
                gallery_image: "", 
              }}
              validationSchema={galleryUpdateSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, handleChange, handleSubmit, values, setFieldValue }) => (
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                  <Row>
                    <Col md={12} className="mb-4">
                      <CommonFormLabel htmlFor="gallery_title" title="Gallery Title" />
                      <Input
                        id="gallery_title"
                        name="gallery_title"
                        type="text"
                        placeholder="Enter Gallery Title"
                        onChange={handleChange}
                        value={values.gallery_title}
                        className={`shadow-none ${errors?.gallery_title && touched.gallery_title ? 'error' : 'correct'}`}
                      />
                      {touched.gallery_title && errors?.gallery_title && (
                        <ValidationFeedback title={errors?.gallery_title} />
                      )}
                    </Col>
                  </Row>

                  {/* gallery Image */}
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="gallery_image" title="Upload New Image" />
                    <FileInput
                      name="gallery_image"
                      onChange={(file) => setFieldValue("gallery_image", file)}
                      error={errors?.gallery_image}
                      touched={touched.gallery_image}
                    />
                    {touched.gallery_image && errors?.gallery_image && (
                      <ValidationFeedback title={errors?.gallery_image} />
                    )}
                  </div>

                  {/* <hr className="my-5" /> */}
                  <Row>
                    <Col md={6} className="">
                  <CommonFormLabel htmlFor="gallery_image" title="Uploaded Image" />
                    <div className="mt-2">
                        <img src={gallery.gallery_image} alt="Gallery" className="img-fluid" height={"150px"} width={"150px"} />
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-5" />


                  <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                    <CommonButton title={isSubmitting ? "Updating..." : "Update"} type="submit" disabled={isSubmitting} className="btn-primary primary-shadow" />
                    <CommonButton title="Cancel" onClick={handleModalClose} className="btn-muted" />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
