import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import TextArea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import SimpleDropdown from "../../components/simpleDropdown";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import { Card, Toast, Form, Col, Row } from "react-bootstrap";
import { Formik } from "formik";
import {
  updateProduct,
  getProductDetails,
} from "../../modules/Product/productSlice";
import {
  getChildCategories,
  getAllCategories,
  getCategoryAttributes,
} from "../../modules/Category/categorySlice";
import { updateProductSchema } from "../../schema/validationSchemas";
import Editor from "../../components/commonEditor";

export default function EditProduct() {
  const { id: productId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const [product, setProduct] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryAttributes, setCategoryAttributes] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);

  const dispatch = useDispatch();

  const handleModalClose = () => {
    setSelectedCategoryId(null);
    setSelectedSubCategoryId(null);
    setCategoryOptions([]);
    setCategoryAttributes([]);
    setSubCategoryOptions([]);
    setSelectedAttributes({});
    setProductImages([]);
    setOldImages([]);
    setProduct(null);
    setIsSubmitting(false);
    setSnackbarMessage("");
    setShowSnackbar(false);
    navigate("/products");
  };

  const fetchCategoryAttributes = useCallback(
      async (subCategoryId) => {
        setLoading(true);
      try {
        const payload = { category_id: subCategoryId };
        await dispatch(getCategoryAttributes(payload)).then((res) => {
          if (res.payload.success) {
            setCategoryAttributes(res.payload.data);
          }
        });
      } catch (error) {
        setLoading(false);
        console.error("Error fetching attributes:", error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, setLoading]
  );

  const fetchProductDetails = useCallback(async () => {
    setLoading(true);
    try {
      const res = await dispatch(getProductDetails(productId));
      if (res.payload.success) {
        const product = res.payload.data;
        setProduct(product);
        setSelectedAttributes(
          product.selected_product_attributes.reduce((acc, attr) => {
            if (!acc[attr.attribute_group_id]) {
              acc[attr.attribute_group_id] = [];
            }
            acc[attr.attribute_group_id].push(attr.attribute_group_value_id);
            return acc;
          }, {})
        );
        setOldImages(product.uploaded_product_images || []);
        setSelectedCategoryId(product.category_id);
        setSelectedSubCategoryId(product.sub_category_id);
        fetchCategoryAttributes(product.sub_category_id);
      } else {
        console.error("Failed to fetch product details", res.payload.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching product details:", error);
    } finally {
        setLoading(false);
      }
  }, [dispatch, productId, fetchCategoryAttributes, setLoading]);

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(getAllCategories()).then((res) => {
        if (res.payload.success) {
          const formattedData = res.payload.data.map((item) => ({
            value: item.id,
            label: item.category_name,
          }));
          setCategoryOptions(formattedData);
        }
      });
    } catch (error) {
        setLoading(false);
      console.error("Error fetching categories:", error);
    } finally {
        setLoading(false);
      }
  }, [dispatch, setLoading]);

  const fetchSubCategories = useCallback(
      async (categoryId) => {
        setLoading(true);
      try {
        const payload = { category_id: categoryId };
        await dispatch(getChildCategories(payload)).then((res) => {
          if (res.payload.success) {
            const formattedData = res.payload.data.map((item) => ({
              value: item.id,
              label: item.category_name,
            }));
            setSubCategoryOptions(formattedData);
          }
        });
      } catch (error) {
        setLoading(false);
        console.error("Error fetching subcategories:", error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, setLoading]
  );

  const handleAttributeChange = (groupId, valueId) => {
    setSelectedAttributes((prevState) => {
      const currentGroupValues = prevState[groupId] || [];
      const updatedGroupValues = currentGroupValues.includes(valueId)
        ? currentGroupValues.filter((id) => id !== valueId)
        : [...currentGroupValues, valueId];

      return { ...prevState, [groupId]: updatedGroupValues };
    });
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = [];

    files.forEach((file) => {
      const exists = productImages.some(
        (img) => img.name === file.name && img.size === file.size
      );
      if (!exists) {
        newFiles.push(file);
      }
    });

    setProductImages((prevImages) => [...prevImages, ...newFiles]);
    event.target.value = null;
  };

  const handleRemoveImage = (index) => {
    setProductImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveOldImage = (imageId) => {
    setOldImages((prevImages) =>
      prevImages.filter((img) => img.id !== imageId)
    );
  };

  useEffect(() => {
    if (productId) {
      fetchCategories();
      fetchProductDetails();
    }
  }, [productId, fetchCategories, fetchProductDetails]);

  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategories(selectedCategoryId);
    }
  }, [selectedCategoryId, fetchSubCategories]);

  useEffect(() => {
    if (selectedSubCategoryId) {
      fetchCategoryAttributes(selectedSubCategoryId);
    }
  }, [selectedSubCategoryId, fetchCategoryAttributes]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    const updatedValues = {
      ...values,
      id: productId,
    };
    const formData = new FormData();
    formData.append("_method", "PUT");

    Object.keys(updatedValues).forEach((key) => {
      formData.append(key, updatedValues[key]);
    });

    Object.entries(selectedAttributes).forEach(
      ([attributeGroupId, attributeGroupValueIds]) => {
        attributeGroupValueIds.forEach((valueId, index) => {
          formData.append(
            `product_attributes[${attributeGroupId}][${index}]`,
            valueId
          );
        });
      }
    );

    productImages.forEach((image, index) => {
      formData.append(`product_images[${index}]`, image);
    });

    const remainingImageIds = oldImages.map((image) => image.id);
    formData.append("previous_images", JSON.stringify(remainingImageIds));

    try {
      const res = await dispatch(updateProduct(formData));
      if (res.payload && res.payload.success) {
        localStorage.setItem("product_notify_message", res.payload.message);
        setProductImages([]);
        setOldImages([]);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        navigate("/products");
        // setSelectedCategoryId(null);
        // setSelectedSubCategoryId(null);
        // setCategoryOptions([]);
        // setCategoryAttributes([]);
        // setSubCategoryOptions([]);
        // setSelectedAttributes({});
        // setProduct(null);
      } else {
        setErrors(res.payload.data);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating product:", error);
      setSnackbarMessage("Failed to update product!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } finally {
      setIsSubmitting(false);
      setSubmitting(false);
    }
  };

  if (!product)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Product Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Formik
        initialValues={{
          category_id: product?.category_id || "",
          sub_category_id: product?.sub_category_id || "",
          product_name: product?.product_name || "",
          product_code: product?.product_code || "",
          description: product?.description || "",
          price: product?.price || "",
          selling_price: product?.selling_price || "",
          quantity: product?.quantity || "",
          min_order_quantity: product?.min_order_quantity || "",
          meta_title: product?.meta_title || "",
          meta_description: product?.meta_description || "",
          meta_keyword: product?.meta_keyword || "",
        }}
        enableReinitialize
        validationSchema={updateProductSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
              <Row>
                <Col md={6} className="">
                  <SimpleDropdown
                    label="Category"
                    name="category_id"
                    value={values.category_id}
                    onChange={(selectedOption) => {
                      setFieldValue("category_id", selectedOption.value);
                      setSelectedCategoryId(selectedOption.value);
                    }}
                    options={categoryOptions}
                    placeholder="Select a Category"
                    error={errors?.category_id}
                    touched={touched.category_id}
                    className={'mb-4'}
                  />
                </Col>
                <Col md={6} className="">
                  <SimpleDropdown
                    label="Sub Category"
                    name="sub_category_id"
                    value={values.sub_category_id}
                    onChange={(selectedOption) => {
                      setSelectedSubCategoryId(selectedOption.value);
                      setFieldValue("sub_category_id", selectedOption.value);
                    }}
                    options={subCategoryOptions}
                    placeholder="Select a Sub Category"
                    error={errors?.sub_category_id}
                    touched={touched.sub_category_id}
                    className={'mb-4'}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6} className="mb-4">
                  <CommonFormLabel
                    htmlFor="product_name"
                    title="Product Name"
                  />
                  <Input
                    id="product_name"
                    name="product_name"
                    type="text"
                    placeholder="Enter Product Name"
                    onChange={handleChange}
                    value={values.product_name}
                    className={`shadow-none ${
                      errors?.product_name && touched.product_name
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.product_name && errors?.product_name && (
                    <ValidationFeedback title={errors?.product_name} />
                  )}
                </Col>

                <Col md={6} className="mb-4">
                  <CommonFormLabel
                    htmlFor="product_code"
                    title="Product Code"
                  />
                  <Input
                    id="product_code"
                    name="product_code"
                    type="text"
                    placeholder="Enter Product Code"
                    onChange={handleChange}
                    value={values.product_code}
                    className={`shadow-none ${
                      errors?.product_code && touched.product_code
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.product_code && errors?.product_code && (
                    <ValidationFeedback title={errors?.product_code} />
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={6} className="mb-4">
                  <CommonFormLabel htmlFor="price" title="Price (₹)" />
                  <Input
                    id="price"
                    name="price"
                    type="number"
                    min="1"
                    placeholder="Enter Price"
                    onChange={handleChange}
                    value={values.price}
                    className={`shadow-none ${
                      errors?.price && touched.price ? "error" : "correct"
                    }`}
                  />
                  {touched.price && errors?.price && (
                    <ValidationFeedback title={errors?.price} />
                  )}
                </Col>

                <Col md={6} className="mb-4">
                  <CommonFormLabel
                    htmlFor="selling_price"
                    title="Selling Price (₹)"
                  />
                  <Input
                    id="selling_price"
                    name="selling_price"
                    type="number"
                    min="1"
                    placeholder="Enter Selling Price"
                    onChange={handleChange}
                    value={values.selling_price}
                    className={`shadow-none ${
                      errors?.selling_price && touched.selling_price
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.selling_price && errors?.selling_price && (
                    <ValidationFeedback title={errors?.selling_price} />
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={6} className="mb-4">
                  <CommonFormLabel htmlFor="quantity" title="Quantity" />
                  <Input
                    id="quantity"
                    name="quantity"
                    type="number"
                    min="1"
                    placeholder="Enter Quantity"
                    onChange={handleChange}
                    value={values.quantity}
                    className={`shadow-none ${
                      errors?.quantity && touched.quantity ? "error" : "correct"
                    }`}
                  />
                  {touched.quantity && errors?.quantity && (
                    <ValidationFeedback title={errors?.quantity} />
                  )}
                </Col>

                <Col md={6} className="mb-4">
                  <CommonFormLabel
                    htmlFor="min_order_quantity"
                    title="min Order Quantity"
                  />
                  <Input
                    id="min_order_quantity"
                    name="min_order_quantity"
                    type="number"
                    min="1"
                    placeholder="Enter min Order Quantity"
                    onChange={handleChange}
                    value={values.min_order_quantity}
                    className={`shadow-none ${
                      errors?.min_order_quantity && touched.min_order_quantity
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.min_order_quantity && errors?.min_order_quantity && (
                    <ValidationFeedback title={errors?.min_order_quantity} />
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-4">
                  <CommonFormLabel htmlFor="description" title="Description" />
                  <Editor
                    value={values.description}
                    onChange={(content) =>
                      setFieldValue("description", content)
                    }
                  />
                  {touched.description && errors?.description && (
                    <ValidationFeedback title={errors?.description} />
                  )}
                </Col>
              </Row>
            </Card>

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">
                Meta Content
              </h4>

              <Row>
                <Col md={12} className="mb-4">
                  <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                  <Input
                    id="meta_title"
                    name="meta_title"
                    type="text"
                    placeholder="Enter Meta Title"
                    onChange={handleChange}
                    value={values.meta_title}
                    className={`shadow-none ${
                      errors?.meta_title && touched.meta_title
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.meta_title && errors?.meta_title && (
                    <ValidationFeedback title={errors?.meta_title} />
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-4">
                  <CommonFormLabel
                    htmlFor="meta_description"
                    title="Meta Description"
                  />
                  <TextArea
                    id="meta_description"
                    name="meta_description"
                    type="text"
                    placeholder="Enter Meta Description"
                    onChange={handleChange}
                    value={values.meta_description}
                    className={`shadow-none ${
                      errors?.meta_description && touched.meta_description
                        ? "error"
                        : "correct"
                    }`}
                    rows="4"
                  />
                  {touched.meta_description && errors?.meta_description && (
                    <ValidationFeedback title={errors?.meta_description} />
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mb-4">
                  <CommonFormLabel
                    htmlFor="meta_keyword"
                    title="Meta Keywords"
                  />
                  <TextArea
                    id="meta_keyword"
                    name="meta_keyword"
                    type="text"
                    placeholder="Enter Meta Keywords"
                    onChange={handleChange}
                    value={values.meta_keyword}
                    className={`shadow-none ${
                      errors?.meta_keyword && touched.meta_keyword
                        ? "error"
                        : "correct"
                    }`}
                    rows="4"
                  />
                  {touched.meta_keyword && errors?.meta_keyword && (
                    <ValidationFeedback title={errors?.meta_keyword} />
                  )}
                </Col>
              </Row>
            </Card>

            {categoryAttributes.length > 0 && (
              <div>
                <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                  <h4 className="mb-4 d-flex justify-content-center">
                    Attributes
                  </h4>
                  <div className="mb-4 text-center common-popup-heading">
                    <h4 className="text-capitalize mb-2 fw-medium">
                      Attributes
                    </h4>
                    <p>Manage Attribute and value for the product</p>
                  </div>
                  {categoryAttributes.map((attributeGroup) => (
                    <div key={attributeGroup.id} className="mb-4">
                      <h5>{attributeGroup.attribute_group_name}</h5>
                      <Row>
                        {attributeGroup.attribute_groups_values.map((value) => (
                          <Col md={4} key={value.id} className="mb-2">
                            <Form.Check
                              type="checkbox"
                              id={`attribute-${attributeGroup.id}-${value.id}`}
                              label={value.attribute_group_value}
                              checked={
                                selectedAttributes[attributeGroup.id]?.includes(
                                  value.id
                                ) || false
                              }
                              onChange={() =>
                                handleAttributeChange(
                                  attributeGroup.id,
                                  value.id
                                )
                              }
                            />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ))}
                </Card>
              </div>
            )}

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">Images</h4>

              <Row>
                <Col md={12} className="mb-4">
                  <CommonFormLabel
                    htmlFor="product_images"
                    title="Product Images"
                  />
                  <Input
                    id="product_images"
                    name="product_images"
                    type="file"
                    onChange={handleImageChange}
                    className={`shadow-none`}
                    multiple
                  />
                  {touched.product_images && errors?.product_images && (
                    <ValidationFeedback title={errors?.product_images} />
                  )}
                </Col>
              </Row>

              {productImages.length > 0 && (
                <>
                  <hr />
                  <div className="mb-4 common-popup-heading">
                    <p className="mb-4">New Selected Images</p>
                    <Row>
                      {productImages.map((image, index) => (
                        <Col md={4} key={index} className="mb-4">
                          <div className="image-preview text-center mb-4">
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`New Preview ${index}`}
                              className="img-fluid rounded border"
                              style={{ width: "200px", height: "auto" }}
                            />
                            <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm mt-2"
                                onClick={() => handleRemoveImage(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              )}

              {oldImages.length > 0 && (
                <>
                  <hr />
                  <div className="mb-4 common-popup-heading">
                    <p className="mb-4">Uploaded Images</p>
                    <Row>
                      {oldImages.map((image) => (
                        <Col md={4} key={image.id} className="mb-4">
                          <div className="image-preview text-center mb-4">
                            <img
                              src={image.image_url}
                              alt={`Uploaded ${image.id}`}
                              className="img-fluid rounded border"
                              style={{ width: "200px", height: "auto" }}

                            />
                            <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm mt-2"
                                onClick={() => handleRemoveOldImage(image.id)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              )}
            </Card>

            <div className="d-flex align-items-center justify-content-end gap-3">
              <CommonButton
                title={isSubmitting ? "Saving..." : "Save"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary"
              />
              <CommonButton
                title="Cancel"
                onClick={(e) => {
                  e.preventDefault();
                  handleModalClose();
                }}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
