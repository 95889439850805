import React, { useState } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Toast, Form, Col, Row, Card } from "react-bootstrap";
import { tagSchema } from "../../schema/validationSchemas";
import { addTag } from "../../modules/Tag/tagSlice";

export default function TagAddNew() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const dispatch = useDispatch();

  const initialEventState = {
    name: "",
  };

  const handleModalClose = () => {
    navigate("/tags");
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    await dispatch(addTag(values))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("tag_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          navigate("/tags");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message || "Failed to add tag!");
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add tag!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>
      <Formik
        initialValues={initialEventState}
        validationSchema={tagSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form
            className="bg-white p-4 rounded-2 shadow-sm "
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
            <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
              <Row className="mb-4">
                <Col md={12} className="mb-4">
                  <CommonFormLabel htmlFor="name" title="Name" />
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors?.name && touched.name
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.name && errors?.name && (
                    <ValidationFeedback title={errors?.name} />
                  )}
                </Col>
              </Row>
            </Card>


            <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
              <CommonButton
                title={isSubmitting ? "Adding..." : "Add"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary primary-shadow"
              />
              <CommonButton
                title="Cancel"
                onClick={handleModalClose}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
