import React, { useEffect } from "react";
import {
  // Toast,
  // ToastContainer,
  Card,
  Row,
  Col
} from "react-bootstrap";

import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import CommonButton from "../../components/commonButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoader } from "../../context/LoaderContext";
import { getDashboardCounts } from "./dashboardSlice";

// import {
//   CheckmarkBadge04Icon,
// } from "../../assets/icons";

export default function List() {
  // const [showSnackbar, setShowSnackbar] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  // const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { dashboardCounts } = useSelector((state) => state.dashboard);
  // const { total_products, total_customers, total_orders, total_categories } = dashboardCounts;

  const token = localStorage.getItem('auth-token');

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        if (token) {
          await dispatch(getDashboardCounts());
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchProducts();
  }, [dispatch, token, setLoading]);


  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted text-primary-hover"
    >
      {children}
    </Link>
  ));

  CustomToggle.displayName = "CustomToggle";

  return (
    <>
      {/* <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer> */}

      <Container fluid className="px-0">
        <h5 className="mb-3">Dashboard Counts</h5>
        <Row className="g-3">
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Parent Categories</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.categories || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Categories"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/categories", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Sub Categories</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.sub_categories || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Categories"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/categories", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Products</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.products || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Products"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/products", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Blogs</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.blogs || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Blogs"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/blogs", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Tags</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.tags || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Tags"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/tags", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Galleries</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.galleries || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Galleries"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/galleries", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Testimonials</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.testimonials || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Testimonials"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/testimonials", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Inquiries</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.inquiries || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Inquiries"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/inquiries", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Target Regions</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.market_areas || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Target Regions"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/target-regions", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Body className="text-center">
                <Card.Title className="mb-3">Team Members</Card.Title>
                <Card.Text className="fw-semibold fs-3 mb-3">
                  {dashboardCounts?.teams || '-'}
                </Card.Text>
              <CommonButton
                title={"View All Members"}
                type="submit"
                className="btn-primary"
                onClick={() => {
                  navigate("/teams", { replace: true });
                }}
              />
              </Card.Body>
            </Card>
          </Col>
      </Row>
      </Container>
    </>
  );
}
