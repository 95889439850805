import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTags, createTag, fetchTag, modifyTag, deleteTagApi, changeStatus } from '../../api/tagApi';

export const getTags = createAsyncThunk('tag/getTags', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchTags(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addTag = createAsyncThunk('tag/addTag', async (payload, { rejectWithValue }) => {
  try {
    const response = await createTag(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getTagDetails = createAsyncThunk('tag/getTagDetails', async (tagId, { rejectWithValue }) => {
  try {
    const response = await fetchTag(tagId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateTag = createAsyncThunk('tag/updateTag', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyTag(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteTag = createAsyncThunk('tag/deleteTag', async (tagId, { rejectWithValue }) => {
  try {
    const response = await deleteTagApi(tagId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('tag/changeDefaultStatus', async (tagId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(tagId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    tags: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tags = action.payload;
      })
      .addCase(getTags.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default tagSlice.reducer;
