import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBlogs, createBlog, fetchBlog, modifyBlog, deleteBlogApi, changeStatus, fetchTags } from '../../api/blogApi';

export const getBlogs = createAsyncThunk('blog/getBlogs', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchBlogs(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addBlog = createAsyncThunk('blog/addBlog', async (payload, { rejectWithValue }) => {
  try {
    const response = await createBlog(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getBlogDetails = createAsyncThunk('blog/getBlogDetails', async (blogId, { rejectWithValue }) => {
  try {
    const response = await fetchBlog(blogId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateBlog = createAsyncThunk('blog/updateBlog', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyBlog(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteBlog = createAsyncThunk('blog/deleteBlog', async (blogId, { rejectWithValue }) => {
  try {
    const response = await deleteBlogApi(blogId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('blog/changeDefaultStatus', async (blogId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(blogId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getTags = createAsyncThunk('setting/getTags', async ({payload}, { rejectWithValue }) => {
  try {
      const response = await fetchTags(payload);
      return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    blogs: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.blogs = action.payload;
      })
      .addCase(getBlogs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default blogSlice.reducer;
