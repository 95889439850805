import React, { useEffect, useState, useCallback } from "react";
import { Modal, Toast, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getBlogDetails } from "../../modules/Blog/blogSlice";
import { Cancel01Icon } from "../../assets/icons";

export default function BlogView({
  show,
  handleClose,
  blogId,
}) {
  const [blog, setBlog] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const dispatch = useDispatch();

  const fetchBlog = useCallback(async () => {
    try {
      const result = await dispatch(getBlogDetails(blogId));
      setBlog(result.payload.data);
    } catch (error) {
      setSnackbarMessage("Failed to load blog details!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    }
  }, [dispatch, blogId]);

  useEffect(() => {
    if (blogId && show) {
      fetchBlog();
    }
  }, [blogId, show, fetchBlog]);

  const handleModalClose = useCallback(() => {
    setBlog(null);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">View Blog</h4>
            <p>Blog details</p>
          </div>

          {blog ? (
            <div>
              <Row>
                <Col md={6} className="mb-4">
                  <strong>Blog Title:</strong>
                  <p>{blog.blog_title}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Author</strong>
                  <p>{blog.author}</p>
                </Col>
              </Row>

              <hr></hr>

              <div className="mb-4">
                <strong>Description:</strong>
                
                 <div
                  dangerouslySetInnerHTML={{ __html: blog.blog_description }}
                />
              </div>
              
              <hr></hr>

              {/* Display Image */}
              {blog.blog_image && (
                <div className="mb-4">
                  <strong>Blog Image:</strong>
                  <div className="mt-2">
                    <img src={blog.blog_image} alt="Blog" className="img-fluid" height={"100px"} width={"100px"} />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p>Loading blog details...</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
