import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSections, updateSection } from "./homePageSlice";
import { Container, Card, Row, Col, Toast, Form } from "react-bootstrap";
import CommonFormLabel from "../../../components/formLabel";
import CommonButton from "../../../components/commonButton";
import Input from "../../../components/input";
import Textarea from "../../../components/commonTextArea";
import Editor from "../../../components/commonEditor";
import { useLoader } from "../../../context/LoaderContext";
import FileInput from "../../../components/fileInput";
import { Formik } from "formik";

const HomePage = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { sections } = useSelector((state) => state.homePage);

  const [editingSectionId, setEditingSectionId] = useState(null);
  const [editingSubSectionId, setEditingSubSectionId] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [SectionData, setSectionData] = useState(null);

  useEffect(() => {
    setLoading(true);
    const fetchSections = async () => {
      try {
        const payload = { slug: "about-us" };
        const result = await dispatch(getSections({ payload }));
        setSectionData(result.payload.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching sections:", error);
        setSnackbarMessage("Failed to load sections.");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchSections();
  }, [dispatch, setLoading]);

  const handleSubmit = async (
    sectionId,
    updatedSectionContents,
    subSectionContents = null
  ) => {
    try {
      const formData = new FormData();
      formData.append("_method", "PUT");

      updatedSectionContents.forEach((content, index) => {
        formData.append(`section_contents[${index}][id]`, content.id);
        if (content.content instanceof File) {
          formData.append(
            `section_contents[${index}][content]`,
            content.content
          );
        } else {
          formData.append(
            `section_contents[${index}][old_content]`,
            content.content
          );
        }

        formData.append(
          `section_contents[${index}][content_type]`,
          content.content_type
        );

        // content.section_content_items?.forEach((item, itemIndex) => {
        //   formData.append(`section_contents[${index}][section_content_items][${itemIndex}][id]`, item.id);
        //   if (item.image instanceof File) {
        //     formData.append(`section_contents[${index}][section_content_items][${itemIndex}][image]`, item.image);
        //   } else {
        //     formData.append(`section_contents[${index}][section_content_items][${itemIndex}][old_image]`, item.image);
        //   }
        //   formData.append(`section_contents[${index}][section_content_items][${itemIndex}][title]`, item.title || "");
        //   formData.append(`section_contents[${index}][section_content_items][${itemIndex}][description]`, item.description || "");
        // });
      });

      // If updating sub-sections, add their contents
      if (subSectionContents) {
        subSectionContents.forEach((subSectionContent, subIndex) => {
          formData.append(
            `sub_sections[${subIndex}][id]`,
            subSectionContent.id
          );
          subSectionContent.section_contents?.forEach((content, index) => {
            formData.append(
              `sub_sections[${subIndex}][section_contents][${index}][id]`,
              content.id
            );
            if (content.content instanceof File) {
              formData.append(
                `sub_sections[${subIndex}][section_contents][${index}][content]`,
                content.content
              );
            } else {
              formData.append(
                `sub_sections[${subIndex}][section_contents][${index}][old_content]`,
                content.content
              );
            }
            formData.append(
              `sub_sections[${subIndex}][section_contents][${index}][content_type]`,
              content.content_type
            );
            // content.section_content_items?.forEach((item, itemIndex) => {
            //   formData.append(`sub_sections[${subIndex}][section_contents][${index}][section_content_items][${itemIndex}][id]`, item.id);
            //   if (item.image instanceof File) {
            //     formData.append(`sub_sections[${subIndex}][section_contents][${index}][section_content_items][${itemIndex}][image]`, item.image);
            //   } else {
            //     formData.append(`sub_sections[${subIndex}][section_contents][${index}][section_content_items][${itemIndex}][old_image]`, item.image);
            //   }
            //   formData.append(`sub_sections[${subIndex}][section_contents][${index}][section_content_items][${itemIndex}][title]`, item.title || "");
            //   formData.append(`sub_sections[${subIndex}][section_contents][${index}][section_content_items][${itemIndex}][description]`, item.description || "");
            // });
          });
        });
      }

      const res = await dispatch(
        updateSection({ sectionId, formData })
      ).unwrap();

      if (res.success) {
        setSnackbarMessage("Section updated successfully");
        setSnackbarVariant("success");
        setShowSnackbar(true);
        dispatch(getSections({ payload: { slug: "about-us" } }));
      } else {
        setSnackbarMessage(res.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    } catch (error) {
      console.error("Error updating section:", error);
      setSnackbarMessage("Failed to update section");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } finally {
      setShowSnackbar(true);
      setEditingSectionId(null);
      setEditingSubSectionId(null);
    }
  };

  const handleEditSubSection = (subSectionId) => {
    setEditingSubSectionId(subSectionId);
  };

  const handleCancel = () => {
    setEditingSectionId(null);
    setEditingSubSectionId(null);
  };

  // const generateDynamicTitle = (content, contentTypeCounts) => {
  //   let type = content.content_type;

  //   if (type === "html_text") {
  //     type = "Rich Text";
  //   }

  //   if (type === "string") {
  //     type = "Text";
  //   }

  //   if (!contentTypeCounts[type]) {
  //     contentTypeCounts[type] = { count: 1 };
  //   } else {
  //     contentTypeCounts[type].count += 1;
  //   }

  //   return contentTypeCounts[type].count > 1
  //     ? `${type.charAt(0).toUpperCase() + type.slice(1)} ${
  //         contentTypeCounts[type].count
  //       }`
  //     : `${type.charAt(0).toUpperCase() + type.slice(1)}`;
  // };

  if (!SectionData)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Page Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Container fluid className="px-0">
        {sections?.data?.map((section) => (
          <Card key={section.id} className="mb-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">{section.title}</h5>
              {editingSectionId !== section.id && (
                <CommonButton
                  title="Edit"
                  type="button"
                  className="btn-primary primary-shadow"
                  onClick={() => setEditingSectionId(section.id)}
                />
              )}
            </Card.Header>
            <Card.Body>
              {editingSectionId === section.id ? (
                <Formik
                  initialValues={{
                    section_contents: [...section.section_contents].sort(
                      (a, b) => a.order - b.order
                    ),
                  }}
                  onSubmit={(values) =>
                    handleSubmit(section.id, values.section_contents)
                  }
                >
                  {({ values, handleChange, setFieldValue, handleSubmit }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          {values.section_contents.map((content, index) => (
                            <Col md={12} key={content.id} className="mb-3">
                              <CommonFormLabel
                                htmlFor={`content_${index}`}
                                title={content.label}
                              />
                              {content.content_type === "image" ? (
                                <>
                                  <FileInput
                                    name={`section_contents[${index}].content`}
                                    onChange={(file) =>
                                      setFieldValue(
                                        `section_contents[${index}].content`,
                                        file
                                      )
                                    }
                                    inputType="image"
                                  />
                                  {content.oldContent && (
                                    <img
                                      src={`${content.oldContent}`}
                                      alt={`Content ${index + 1}`}
                                      className="mt-2"
                                      style={{
                                        width: "150px",
                                        height: "auto",
                                        border: "1px solid #ddd",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  )}
                                </>
                              ) : content.content_type === "text" ? (
                                <Textarea
                                  id={`section_contents[${index}].content`}
                                  name={`section_contents[${index}].content`}
                                  placeholder={`Enter Content ${index + 1}`}
                                  onChange={handleChange}
                                  value={content.content}
                                  className="shadow-none"
                                  rows={4}
                                />
                              ) : content.content_type === "html_text" ? (
                                <Editor
                                  value={content.content}
                                  onChange={(content) =>
                                    setFieldValue(
                                      `section_contents[${index}].content`,
                                      content
                                    )
                                  }
                                  height="250px"
                                />
                              ) : (
                                <Input
                                  id={`section_contents[${index}].content`}
                                  name={`section_contents[${index}].content`}
                                  type="text"
                                  placeholder={`Enter Content ${index + 1}`}
                                  onChange={handleChange}
                                  value={content.content}
                                  className="mt-2 shadow-none"
                                />
                              )}
                            </Col>
                          ))}
                        </Row>
                        <div className="d-flex justify-content-end gap-3 mt-4">
                          <CommonButton
                            title="Save Changes"
                            type="submit"
                            className="btn-primary primary-shadow"
                          />
                          <CommonButton
                            title="Cancel"
                            type="button"
                            className="btn-muted"
                            onClick={handleCancel}
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              ) : (
                <>
                  <Row>
                    {section.section_contents.map((content, index) => (
                      <Col md={12} key={content.id} className="mb-3">
                        <CommonFormLabel
                          htmlFor={`content_${index}`}
                          title={content.label}
                        />
                        {content.content_type === "image" ? (
                          <div className="mt-2 p-2">
                            <img
                              src={`${content.content}`}
                              alt={`Content ${index + 1}`}
                              style={{
                                width: "150px",
                                height: "auto",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        ) : content.content_type === "html_text" ? (
                          <div className="mt-2 p-2">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content.content,
                              }}
                            />
                          </div>
                        ) : (
                          <p className="mt-2 p-2">{content.content}</p>
                        )}
                      </Col>
                    ))}
                  </Row>

                  {/* Render sub-sections */}
                  {section.sub_sections && section.sub_sections.length > 0 && (
                    <>
                      <hr />
                      <h4 className="mt-4 justify-content-center d-flex">
                        Sub Sections Of {section.title}
                      </h4>
                      <Row>
                        {section.sub_sections.map((subSection) => (
                          <Col key={subSection.id} md={12} className="mb-3">
                            <Card className="mb-3">
                              <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5>{subSection.title}</h5>
                                {editingSubSectionId !== subSection.id && (
                                  <CommonButton
                                    title="Edit"
                                    type="button"
                                    className="btn-primary primary-shadow"
                                    onClick={() =>
                                      handleEditSubSection(subSection.id)
                                    }
                                  />
                                )}
                              </Card.Header>
                              <Card.Body>
                                {editingSubSectionId === subSection.id ? (
                                  <Formik
                                    initialValues={{
                                      section_contents: [
                                        ...subSection.section_contents,
                                      ].sort((a, b) => a.order - b.order),
                                    }}
                                    onSubmit={(values) =>
                                      handleSubmit(
                                        subSection.id,
                                        values.section_contents,
                                        section.sub_sections
                                      )
                                    }
                                  >
                                    {({
                                      values,
                                      handleChange,
                                      setFieldValue,
                                      handleSubmit,
                                    }) => {
                                      return (
                                        <Form onSubmit={handleSubmit}>
                                          <Row>
                                            {values.section_contents.map(
                                              (content, index) => (
                                                <Col
                                                  md={12}
                                                  key={content.id}
                                                  className="mb-3"
                                                >
                                                  <CommonFormLabel
                                                    htmlFor={`content_${index}`}
                                                    title={content.label}
                                                  />
                                                  {content.content_type ===
                                                  "image" ? (
                                                    <>
                                                      <FileInput
                                                        name={`section_contents[${index}].content`}
                                                        onChange={(file) =>
                                                          setFieldValue(
                                                            `section_contents[${index}].content`,
                                                            file
                                                          )
                                                        }
                                                        inputType="image"
                                                      />
                                                      {content.oldContent && (
                                                        <img
                                                          src={`${content.oldContent}`}
                                                          alt={`Content ${
                                                            index + 1
                                                          }`}
                                                          className="mt-2"
                                                          style={{
                                                            width: "150px",
                                                            height: "auto",
                                                            border:
                                                              "1px solid #ddd",
                                                            borderRadius: "5px",
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  ) : content.content_type ===
                                                    "text" ? (
                                                    <Textarea
                                                      id={`section_contents[${index}].content`}
                                                      name={`section_contents[${index}].content`}
                                                      placeholder={`Enter Content ${
                                                        index + 1
                                                      }`}
                                                      onChange={handleChange}
                                                      value={content.content}
                                                      className="shadow-none"
                                                      rows={4}
                                                    />
                                                  ) : content.content_type ===
                                                    "html_text" ? (
                                                    <Editor
                                                      value={content.content}
                                                      onChange={(content) =>
                                                        setFieldValue(
                                                          `section_contents[${index}].content`,
                                                          content
                                                        )
                                                      }
                                                      height="250px"
                                                    />
                                                  ) : (
                                                    <Input
                                                      id={`section_contents[${index}].content`}
                                                      name={`section_contents[${index}].content`}
                                                      type="text"
                                                      placeholder={`Enter Content ${
                                                        index + 1
                                                      }`}
                                                      onChange={handleChange}
                                                      value={content.content}
                                                      className="mt-2 shadow-none"
                                                    />
                                                  )}
                                                </Col>
                                              )
                                            )}
                                          </Row>
                                          <div className="d-flex justify-content-end gap-3 mt-4">
                                            <CommonButton
                                              title="Save Changes"
                                              type="submit"
                                              className="btn-primary primary-shadow"
                                            />
                                            <CommonButton
                                              title="Cancel"
                                              type="button"
                                              className="btn-muted"
                                              onClick={handleCancel}
                                            />
                                          </div>
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                ) : (
                                  <>
                                    {subSection.section_contents.map(
                                      (content, index) => (
                                        <Col
                                          md={12}
                                          key={content.id}
                                          className="mb-3"
                                        >
                                          <CommonFormLabel
                                            htmlFor={`content_${index}`}
                                            title={content.label}
                                          />
                                          {content.content_type === "image" ? (
                                            <div className="mt-2 p-2">
                                              <img
                                                src={`${content.content}`}
                                                alt={`Content ${index + 1}`}
                                                style={{
                                                  width: "150px",
                                                  height: "auto",
                                                  border: "1px solid #ddd",
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            </div>
                                          ) : content.content_type ===
                                            "html_text" ? (
                                            <div className="mt-2 p-2">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: content.content,
                                                }}
                                              />
                                            </div>
                                          ) : (
                                            <p className="mt-2 p-2">
                                              {content.content}
                                            </p>
                                          )}
                                        </Col>
                                      )
                                    )}
                                  </>
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        ))}
      </Container>
    </>
  );
};

export default HomePage;
