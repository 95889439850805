import "react-quill/dist/quill.snow.css";
import ReactQuill from 'react-quill';
import './Editor.css';

export default function Editor({
    value,
    onChange,
    height = "250px"
  }) {

    // const modules1 = {
    //   toolbar: [
    //     // Default options
    //     [{ header: [1, 2, 3, false] }],
    //     ["bold", "italic", "underline", "strike"], // Formatting options
    //     [{ list: "ordered" }, { list: "bullet" }], // Lists
    //     [{ indent: "-1" }, { indent: "+1" }], // Indentation
    //     [{ align: [] }], // Alignment
    //     [{ color: [] }, { background: [] }], // Text color and background color
    //     ["link", "image", "video"], // Links, images, and videos
    //     ["clean"], // Remove formatting
    //   ],
    // };

    const modules = {
      toolbar: [
        // Text formatting
        [{ font: [] }], // Font family
        [{ size: ["small", false, "large", "huge"] }], // Font size
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers (h1 - h6)
    
        // Inline styling
        ["bold", "italic", "underline", "strike"], // Bold, italic, underline, strikethrough
    
        // Text alignment
        [{ align: [] }], // Align left, center, right, justify
    
        // List styling
        [{ list: "ordered" }, { list: "bullet" }], // Ordered and unordered lists
        [{ indent: "-1" }, { indent: "+1" }], // Indent and outdent
    
        // Color styling
        [{ color: [] }, { background: [] }], // Text color and background color
    
        // Blocks
        [{ script: "sub" }, { script: "super" }], // Subscript and superscript
        ["blockquote", "code-block"], // Blockquote and code block
    
        // Links and media
        ["link", "image", "video"], // Add links, images, and videos
    
        // Clean formatting
        ["clean"], // Remove all formatting
      ],
    };
    
    // Define formats to limit or allow specific features
    // const formats1 = [
    //   "header",
    //   "bold",
    //   "italic",
    //   "underline",
    //   "strike",
    //   "list",
    //   "bullet",
    //   "indent",
    //   "align",
    //   "color",
    //   "background",
    //   "link",
    //   "image",
    //   "video",
    // ];

    const formats = [
      // Font and size
      "font",
      "size",
    
      // Headers
      "header",
    
      // Inline styles
      "bold",
      "italic",
      "underline",
      "strike",
    
      // Text alignment
      "align",
    
      // Lists
      "list",
      "bullet",
    
      // Indentation
      "indent",
    
      // Color and background
      "color",
      "background",
    
      // Scripts (subscript and superscript)
      "script",
    
      // Blocks
      "blockquote",
      "code-block",
    
      // Links and media
      "link",
      "image",
      "video",
    ];

    
    return (
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules} // Pass custom modules
        formats={formats} // Pass allowed formats
        className="custom-editor"
        style={{
          height: `calc(${height} + 42px)`, // 42px accounts for the toolbar height
          marginBottom: "4rem",
        }}
      />
    );
  }
