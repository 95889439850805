import React from "react";
import { Form } from "react-bootstrap";
import "./formLabel.css"

export default function CommonFormLabel({ htmlFor, title, className = null, style = null }) {
  return (
    <Form.Label htmlFor={htmlFor} className={`mb-1 common-form-label ${className}`} style={style}>
      {title}
    </Form.Label>
  );
}
