import { apiHelper } from '../../utils/apiHelper';

// const API_URL = `${process.env.REACT_APP_API_URL}/api/org/page`;
const API_URL2 = `${process.env.REACT_APP_API_URL}/api/org/section`;

// const headers = {
//   "Content-Type": "multipart/form-data",
//   "Accept": "application/json"
// };

const headers2 = {
  "Content-Type": "multipart/form-data",
};

export const fetchSections = (payload) => apiHelper(API_URL2, 'GET', payload);
export const modifySection = (sectionId, payload) => apiHelper(`${API_URL2}/${sectionId}`, 'POST', payload, headers2);
