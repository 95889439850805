import React, { useState } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { Card, Toast, Form } from "react-bootstrap";
import { Formik } from "formik";
import { addTeam } from "../../modules/Team/teamSlice";
import { teamSchema } from "../../schema/validationSchemas";

export default function Add() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  const initialEventState = {
    member_name: "",
    member_position: "",
    facebook_link: "",
    instagram_link: "",
    twitter_link: "",
    member_image: "",
  };

  const handleModalClose = () => {
    navigate("/teams");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setFieldValue("member_image", file);
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    await dispatch(addTeam(values))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("team_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          navigate("/teams");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(
            res.payload.message || "Failed to add team member!"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add team member!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Formik
        initialValues={initialEventState}
        validationSchema={teamSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="bg-white p-4 rounded-2 shadow-sm"
          >
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
              <div className="mb-4">
                <CommonFormLabel htmlFor="member_name" title="Member Name" />
                <Input
                  id="member_name"
                  name="member_name"
                  type="text"
                  placeholder="Enter Member Name"
                  onChange={handleChange}
                  className={`shadow-none ${
                    errors?.member_name && touched.member_name
                      ? "error"
                      : "correct"
                  }`}
                />
                {touched.member_name && errors?.member_name && (
                  <ValidationFeedback title={errors?.member_name} />
                )}
              </div>

              <div className="mb-4">
                <CommonFormLabel
                  htmlFor="member_position"
                  title="Member Position"
                />
                <Input
                  id="member_position"
                  name="member_position"
                  type="text"
                  placeholder="Enter Member Position"
                  onChange={handleChange}
                  className={`shadow-none ${
                    errors?.member_position && touched.member_position
                      ? "error"
                      : "correct"
                  }`}
                />
                {touched.member_position && errors?.member_position && (
                  <ValidationFeedback title={errors?.member_position} />
                )}
              </div>
            </Card>

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
            <h4 className="mb-4 d-flex justify-content-center">Social Links</h4>
            <div className="mb-4">
              <CommonFormLabel htmlFor="facebook_link" title="Facebook Link" />
              <Input
                id="facebook_link"
                name="facebook_link"
                type="text"
                placeholder="Enter Facebook Link"
                onChange={handleChange}
                className={`shadow-none ${
                  errors?.facebook_link && touched.facebook_link
                    ? "error"
                    : "correct"
                }`}
              />
              {touched.facebook_link && errors?.facebook_link && (
                <ValidationFeedback title={errors?.facebook_link} />
              )}
            </div>

            <div className="mb-4">
              <CommonFormLabel
                htmlFor="instagram_link"
                title="Instagram Link"
              />
              <Input
                id="instagram_link"
                name="instagram_link"
                type="text"
                placeholder="Enter Instagram Link"
                onChange={handleChange}
                className={`shadow-none ${
                  errors?.instagram_link && touched.instagram_link
                    ? "error"
                    : "correct"
                }`}
              />
              {touched.instagram_link && errors?.instagram_link && (
                <ValidationFeedback title={errors?.instagram_link} />
              )}
            </div>

            <div className="mb-4">
              <CommonFormLabel htmlFor="twitter_link" title="Twitter Link" />
              <Input
                id="twitter_link"
                name="twitter_link"
                type="text"
                placeholder="Enter Twitter Link"
                onChange={handleChange}
                className={`shadow-none ${
                  errors?.twitter_link && touched.twitter_link
                    ? "error"
                    : "correct"
                }`}
              />
              {touched.twitter_link && errors?.twitter_link && (
                <ValidationFeedback title={errors?.twitter_link} />
              )}
            </div>
            </Card>

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">Image</h4>
            <div className="mb-4">
              <CommonFormLabel htmlFor="member_image" title="Upload Image" />
              <FileInput
                name="member_image"
                // onChange={(file) => setFieldValue("member_image", file)}
                onChange={(file) => handleImageChange(file, setFieldValue)}
                error={errors?.member_image}
                touched={touched.member_image}
              />
              {touched.member_image && errors?.member_image && (
                <ValidationFeedback title={errors?.member_image} />
              )}
              {imagePreview && (
                  <div className="mt-3">
                    <img
                      src={imagePreview}
                      alt="Blog Preview"
                      style={{
                        width: "200px",
                        height: "auto",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                )}
            </div>
            </Card>

            <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
              <CommonButton
                title={isSubmitting ? "Adding..." : "Add"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary primary-shadow"
              />
              <CommonButton
                title="Cancel"
                onClick={handleModalClose}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
