import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function SidebarItem({ to, icon, title, className, children = null, dropdownIcon = null }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    // if ((location.pathname === "/home-page")) {
    //   return true;
    // }
    return location.pathname === path;
  };

  return (
    <li className={`sidebar-item ${isOpen ? "open" : ""}`}>
      {children ? (
        <>
          {/* Parent item for the dropdown */}
          <div
            className="d-flex align-items-center gap-2 text-decoration-none cursor-pointer"
            onClick={toggleDropdown}
          >
            <div className="ms-4">{icon}</div> 
            <div className="text-capitalize">{title}</div>
            <div className={`ms-3 rotate-icon ${isOpen ? "rotate" : ""}`}>
              {dropdownIcon}
            </div>
          </div>

          {/* Dropdown content */}
          {isOpen && (
            <ul className="dropdown-content list-unstyled">
              {children.map((child, index) => (
                <li key={index} className="sidebar-subitem">
                  <Link
                    to={child.link}
                    className={`d-flex align-items-center gap-2 text-decoration-none ${className} ${isActive(child.link) ? "active" : ""}`}
                  >
                    <div className="ms-4">{child.icon}</div> 
                    <div className="text-capitalize">{child.title}</div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </>
      ) : (
        // Standard sidebar item
        <Link
          to={to}
          className={`d-flex align-items-center gap-2 text-decoration-none ${className}`}
        >
          {icon}
          <div className="text-capitalize">{title}</div>
        </Link>
      )}
    </li>
  );
}
