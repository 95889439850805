import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTags, deleteTag, changeActiveStatus } from './tagSlice';
import { Container, Modal, Toast, Button } from "react-bootstrap";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/commonButton";
import CommonPagination from "../../components/commonPagination";
import CommonTable from '../../components/commonTable';
import { Add01Icon } from "../../assets/icons";
import { useLoader } from "../../context/LoaderContext";
// import TagAdd from './TagAdd';
// import TagEdit from './TagEdit';
// import TagView from './TagView';

const TagList = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const { tags } = useSelector((state) => state.tags);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
//   const [showModal, setShowModal] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState('');
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const limit = 10;
  const totalPages = Math.ceil(tags?.total_records / limit);
  const total = tags?.total_records || 0;

  const token = localStorage.getItem('auth-token');

  let tagMessage = localStorage.getItem('tag_notify_message');

  useEffect(() => {
    if (tagMessage) {
      setSnackbarMessage(tagMessage);
      setSnackbarVariant("success");
      setShowSnackbar(true);
      localStorage.removeItem('tag_notify_message');
    }
  }, [tagMessage])

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      const payload = {
        page: currentPage,
        search: searchTerm,
        limit: limit,
        token
      };
  
      try {
        if (token) {
          await dispatch(getTags({ payload }));
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching tags:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchTags();
  }, [dispatch, token, currentPage, searchTerm, setLoading]);
  

  const columns = [
    { label: 'Title', key: 'name' },
    { label: 'created at', key: 'created_at', type: 'date' },
    {label: 'Status', key: 'is_active', type: 'status' },
  ];

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const handleDeleteNew = async () => {
    if (selectedTagId) {
      await dispatch(deleteTag(selectedTagId)).then((res) => {
        if(res.payload.success) {
          setShowDeleteModal(false);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            token
          };
          dispatch(getTags({ payload }));
        } else {
          setShowDeleteModal(false);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
      });
    }
  };

  const handleEdit = (row) => {
    // setSelectedTagId(row.id);
    navigate(`/tag-edit/${row.id}`, { replace: true });
    // handleOpen();

  };

//   const handleOpen = () => {
//     setTimeout(() => {
//       setShowEditModal(true);
//     }, 1000);
//   };
  

  const handleDelete = (row) => {
    setSelectedTagId(row.id);
    setShowDeleteModal(true);
  };

  const handleView = (row) => {
    setSelectedTagId(row.id);
    // setShowViewModal(true);
  };

  const handleStatusChange = async (row) => {
    setSelectedTagId(row.id);
    setShowStatusModal(true);
  }

  const handleStatusChangeNew = async () => {
    await dispatch(changeActiveStatus(selectedTagId)).then((res) => {
      if(res.payload.success) {
        setShowStatusModal(false);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        const payload = {
          page: currentPage,
          search: searchTerm,
          limit: limit,
          token
        };
        dispatch(getTags({ payload }));
      } else {
        setShowStatusModal(false);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    });
  }

  return (
    <>
      <Container fluid className="px-0">
        {/* <TagAdd 
          show={showModal}
          // onHide={() => setShowModal(false)}
          handleClose={() => setShowModal(false)}
          currentPage={currentPage}
          searchTerm={searchTerm}
          limit={limit}
        />
        <TagEdit
          tagId={selectedTagId}
          show={showEditModal}
          // onHide={() => setShowEditModal(false)}
          handleClose={() => setShowEditModal(false)}
          currentPage={currentPage}
          searchTerm={searchTerm}
          limit={limit}
        />
        <TagView
          show={showViewModal}
          handleClose={() => setShowViewModal(false)}
          tagId={selectedTagId} 
        /> */}

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this tag?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteNew}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Status Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to change status?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowStatusModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleStatusChangeNew}>
              Change
            </Button>
          </Modal.Footer>
        </Modal>

        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
        >
          <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
        </Toast>

        <div className="p-4 d-flex align-items-center justify-content-between gap-4">
          <Input
            type="search"
            value={searchTerm}
            placeholder="Search Tag"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="shadow-none base-search"
          />

          <CommonButton
            icon={<Add01Icon width={16} height={16} color="#ffffff" strokeWidth="2" />}
            title="Add Tag"
            className="btn-primary common-one-shadow"
            onClick={() => {
              // setShowModal(true);
              navigate("/tag-add", { replace: true });
            }}
          />
        </div>

        <div className="bg-white rounded-2 overflow-hidden common-shadow">
            <CommonTable
              columns={columns}
              data={tags?.data || []}
              url={tags?.url || []}
              showActions={true}
              actions={{
                showEdit: true,
                showDelete: true,
                showView: false,
                onEdit: handleEdit,
                onDelete: handleDelete,
                onView: handleView
              }}
              onStatusChange={handleStatusChange}
            />
        </div>

        <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
          <p className="custom-table-infotext">
            Displaying {(limit * (currentPage - 1)) + 1} to {Math.min(limit * currentPage, total)} of {total} Tags
          </p>

          <div>
            {totalPages > 1 ? (
              <CommonPagination
                totalItems={total}
                itemsPerPage={limit}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </>
  );
};

export default TagList;
