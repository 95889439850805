import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSections, modifySection } from "../../../api/pages/homePageApi";

export const getSections = createAsyncThunk("section/getSections", async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchSections(payload);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching sections:", error);
  }
});

// export const updateSection = createAsyncThunk("section/updateSection", async ({ sectionId, updatedSectionContents }) => {
//     try {
//       const response = await modifySection(sectionId, {
//         section_contents: updatedSectionContents,
//       });
//       return response.data.data;
//     } catch (error) {
//       console.error("Error updating section:", error);
//     }
//   }
// );

export const updateSection = createAsyncThunk(
  "section/updateSection",
  async ({ sectionId, formData }) => {
    try {
      const response = await modifySection(sectionId, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating section:", error);
      throw error;
    }
  }
);

const homePageSlice = createSlice({
  name: "homePage",
  initialState: {
    sections: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSections.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sections = action.payload;
      })
      .addCase(getSections.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default homePageSlice.reducer;
