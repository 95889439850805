import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import { useParams, useNavigate } from "react-router-dom";
import CommonButton from "../../components/commonButton";
import { updateTag, getTagDetails } from "../../modules/Tag/tagSlice";
import { useLoader } from "../../context/LoaderContext";
import { tagUpdateSchema } from "../../schema/validationSchemas";
import CommonFormLabel from "../../components/formLabel";
import { Toast, Form, Row, Col, Card } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function TagEditNew() {
  const { id: TagId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const [tag, setTag] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchTag = async () => {
      setLoading(true);
      try {
        const result = await dispatch(getTagDetails(TagId)).unwrap();
        setTag(result.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching tag:", error);
        setSnackbarMessage("Failed to load tag details.");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    if (TagId) fetchTag();
  }, [TagId, dispatch, setLoading]);

  const handleCancel = () => {
    navigate("/tags");
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("id", TagId);
    formData.append("_method", "PUT");
    formData.append("name", values.name);

    try {
      const res = await dispatch(updateTag(formData)).unwrap();
      if (res.success) {
        localStorage.setItem("tag_notify_message", res.message);
        setSnackbarMessage(res.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        navigate("/tags");
      } else {
        setErrors(res.data);
        setSnackbarMessage(res.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Failed to update tag!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    } finally {
      setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  if (!tag)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Tag Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Formik
        initialValues={{
          name: tag?.name || "",
        }}
        validationSchema={tagUpdateSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form
            className="bg-white p-4 rounded-2 shadow-sm"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
            <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
                <Row>
                <Col md={12} className="mb-4">
                    <CommonFormLabel htmlFor="name" title="Name" />
                    <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    onChange={handleChange}
                    value={values.name}
                    className={`shadow-none ${
                        errors?.name && touched.name ? "error" : "correct"
                    }`}
                    />
                    {touched.name && errors?.name && (
                    <ValidationFeedback title={errors?.name} />
                    )}
                </Col>
                </Row>
            </Card>

            <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
              <CommonButton
                title={isSubmitting ? "Updating..." : "Update"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary primary-shadow"
              />
              <CommonButton
                title="Cancel"
                onClick={handleCancel}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
      </>
  );
}