import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import TextArea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import SimpleDropdown from "../../components/simpleDropdown";
import FileInput from "../../components/fileInput";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoader } from "../../context/LoaderContext";
import { Toast, Form, Row, Col, Card } from "react-bootstrap";
import { Formik } from "formik";
import {
  updateCategory,
  getCategoryDetails,
  getAllCategories,
} from "../../modules/Category/categorySlice";
import { categoryUpdateSchema } from "../../schema/validationSchemas";
import Editor from "../../components/commonEditor";

export default function Edit() {
  const { id: categoryId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const [category, setCategory] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [brochurePath, setBrochurePath] = useState(null);

  const [attributes, setAttributes] = useState([
    { id: 1, name: "", values: [""] },
  ]);
  const dispatch = useDispatch();

  const resetAttributes = () => {
    setAttributes([{ id: 1, name: "", values: [""] }]);
  };

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        category_id: categoryId,
      };
      await dispatch(getAllCategories(payload)).then((res) => {
        if (res.payload.success) {
          const formattedData = res.payload.data.map((item) => ({
            value: item.id,
            label: item.category_name,
          }));
          setCategoryOptions(formattedData);
        } else {
          setCategoryOptions([]);
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching categories:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, categoryId, setLoading]);

  const fetchCategoryDetails = useCallback(async () => {
    setLoading(true);
    try {
      const result = await dispatch(getCategoryDetails(categoryId));
      const categoryData = result.payload.data;

      const mappedAttributes = categoryData.attribute_groups.map(
        (group, index) => ({
          id: group.id,
          name: group.attribute_group_name,
          values: group.attribute_groups_values.map(
            (value) => value.attribute_group_value
          ),
        })
      );

      setCategory(result.payload.data);
      setAttributes(mappedAttributes);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching category:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, categoryId, setLoading]);

  useEffect(() => {
    setCategory(null);
    if (categoryId) {
      fetchCategories();
      fetchCategoryDetails();
    }
  }, [categoryId, fetchCategories, fetchCategoryDetails]);

  const handleCancel = () => {
    setCategory(null);
    resetAttributes();
    navigate("/categories");
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);

    const dataToSubmit = {
      ...values,
      id: categoryId,
      _method: "PUT",
      attribute_groups: attributes.map((attribute) => ({
        name: attribute.name,
        values: attribute.values.filter((value) => value !== ""),
      })),
    };

    const formData = new FormData();

    Object.entries(dataToSubmit).forEach(([key, value]) => {
      if (key === "attribute_groups") {
        formData.append(key, JSON.stringify(value)); // Convert to JSON string for backend
      } else {
        formData.append(key, value);
      }
    });

    await dispatch(updateCategory(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("category_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          setCategory(null);
          navigate("/categories");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(
            res.payload.message || "Failed to update category!"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update category!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  // Functions to handle adding/removing attributes and values
  const addAttribute = () => {
    setAttributes([
      ...attributes,
      { id: attributes.length + 1, name: "", values: [""] },
    ]);
  };

  const removeAttribute = (attributeId) => {
    setAttributes(attributes.filter((attr) => attr.id !== attributeId));
  };

  const addAttributeValue = (attributeId) => {
    const updatedAttributes = attributes.map((attr) =>
      attr.id === attributeId ? { ...attr, values: [...attr.values, ""] } : attr
    );
    setAttributes(updatedAttributes);
  };

  const removeAttributeValue = (attributeId, valueIndex) => {
    const updatedAttributes = attributes.map((attr) => {
      if (attr.id === attributeId) {
        const updatedValues = [...attr.values];
        updatedValues.splice(valueIndex, 1);
        return { ...attr, values: updatedValues };
      }
      return attr;
    });
    setAttributes(updatedAttributes);
  };

  const handleAttributeChange =
    (attributeId, name, index = null) =>
    (e) => {
      const updatedAttributes = attributes.map((attr) => {
        if (attr.id === attributeId) {
          if (name === "name") {
            return { ...attr, name: e.target.value };
          } else if (name === "values" && index !== null) {
            const updatedValues = [...attr.values];
            updatedValues[index] = e.target.value;
            return { ...attr, values: updatedValues };
          }
        }
        return attr;
      });
      setAttributes(updatedAttributes);
    };

  if (!category)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Category Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>
      {category && (
        <Formik
          initialValues={{
            parent_category_id: category?.parent_category_id || "",
            category_name: category.category_name || "",
            page_title: category.page_title || "",
            page_sub_title: category.page_sub_title || "",
            category_video_link: category.category_video_link || "",
            category_image: "",
            image_alt: category.image_alt || "",
            category_banner_image: "",
            brochure_file: "",
            category_description: category.category_description || "",
            meta_title: category.meta_title || "",
            meta_description: category.meta_description || "",
            meta_keyword: category.meta_keyword || "",
            default_category_description:
              category.default_category_description || "",
            default_meta_title: category.default_meta_title || "",
            default_meta_description: category.default_meta_description || "",
            default_meta_keyword: category.default_meta_keyword || "",
          }}
          validationSchema={categoryUpdateSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* <div className="mb-4"> */}
              {/* <h3 className="mb-4 text-center">Edit Category</h3> */}

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
                <Row>
                  <Col md={6} className="">
                    <CommonFormLabel htmlFor="category_name" title="Name" />
                    <Input
                      id="category_name"
                      name="category_name"
                      type="text"
                      placeholder="Enter Category Name"
                      onChange={(e) => {
                        const categoryName = e.target.value;
                        setFieldValue("category_name", categoryName);
                        setFieldValue("image_alt", categoryName);

                        // Only update the image_alt if the user has not manually edited it
                        // if (!touched.image_alt || values.image_alt === values.category_name) {
                        //   setFieldValue("image_alt", categoryName);
                        // }
                      }}
                      value={values.category_name}
                      className={`shadow-none ${
                        errors?.category_name && touched.category_name
                          ? "error"
                          : "correct"
                      }`}
                    />
                    {touched.category_name && errors?.category_name && (
                      <ValidationFeedback title={errors?.category_name} />
                    )}
                  </Col>

                  <Col md={6} className="">
                    <SimpleDropdown
                      label="Parent Category (Optional)"
                      name="parent_category_id"
                      value={values.parent_category_id}
                      onChange={(selectedOption) =>
                        setFieldValue(
                          "parent_category_id",
                          selectedOption.value
                        )
                      }
                      options={categoryOptions}
                      placeholder="Select a Parent Category"
                      error={errors?.parent_category_id}
                      touched={touched.parent_category_id}
                      className={'mb-4'}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="page_title" title="Page Title" />
                    <Input
                      id="page_title"
                      name="page_title"
                      type="text"
                      placeholder="Enter Page Title"
                      onChange={handleChange}
                      value={values.page_title}
                      className={`shadow-none ${
                        errors?.page_title && touched.page_title
                          ? "error"
                          : "correct"
                      }`}
                    />
                    {touched.page_title && errors?.page_title && (
                      <ValidationFeedback title={errors?.page_title} />
                    )}
                  </Col>

                  <Col md={6} className="mb-4">
                    <CommonFormLabel
                      htmlFor="page_sub_title"
                      title="Page Sub Title"
                    />
                    <Input
                      id="page_sub_title"
                      name="page_sub_title"
                      type="text"
                      placeholder="Enter Page Sub Title"
                      onChange={handleChange}
                      value={values.page_sub_title}
                      className={`shadow-none ${
                        errors?.page_sub_title && touched.page_sub_title
                          ? "error"
                          : "correct"
                      }`}
                    />
                    {touched.page_sub_title && errors?.page_sub_title && (
                      <ValidationFeedback title={errors?.page_sub_title} />
                    )}
                  </Col>
                </Row>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="category_video_link"
                    title="Video Link"
                  />
                  <Input
                    id="category_video_link"
                    name="category_video_link"
                    type="text"
                    placeholder="Enter Video Link"
                    onChange={handleChange}
                    value={values.category_video_link}
                    className={`shadow-none ${
                      errors?.category_video_link && touched.category_video_link
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.category_video_link &&
                    errors?.category_video_link && (
                      <ValidationFeedback title={errors?.category_video_link} />
                    )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="category_description"
                    title="Category Description"
                  />
                  <Editor
                    value={values.category_description}
                    onChange={(content) =>
                      setFieldValue("category_description", content)
                    }
                  />
                  {touched.category_description &&
                    errors?.category_description && (
                      <ValidationFeedback
                        title={errors?.category_description}
                      />
                    )}
                </div>

                {/* Default Category Description */}
                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="default_category_description"
                    title="Dynamic Description"
                  />
                  <CommonFormLabel className="ms-2 text-primary" htmlFor="note" title='(Note:- You can use "target_region" to place the selected Target Region in category description)'  />
                  <Editor
                    value={values.default_category_description}
                    onChange={(content) =>
                      setFieldValue("default_category_description", content)
                    }
                  />
                  {touched.default_category_description &&
                    errors?.default_category_description && (
                      <ValidationFeedback
                        title={errors?.default_category_description}
                      />
                    )}
                </div>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">
                Meta Content
              </h4>
                <div className="mb-4">
                  <CommonFormLabel htmlFor="default_meta_title" title="Meta Title" />
                  {/* <CommonFormLabel className="ms-2 text-primary" htmlFor="note" title='(Note:- You can use "target_region" to place the selected Target Region in category description)'  /> */}
                  <Input
                    id="default_meta_title"
                    name="default_meta_title"
                    type="text"
                    placeholder="Enter Meta Title"
                    onChange={handleChange}
                    value={values.default_meta_title}
                    className={`shadow-none ${
                      errors?.default_meta_title && touched.default_meta_title
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.default_meta_title && errors?.default_meta_title && (
                    <ValidationFeedback title={errors?.default_meta_title} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="default_meta_description"
                    title="Meta Description"
                  />
                  {/* <CommonFormLabel className="ms-2 text-primary" htmlFor="note" title='(Note:- You can use "target_region" to place the selected Target Region in category description)'  /> */}
                  <TextArea
                    id="default_meta_description"
                    name="default_meta_description"
                    placeholder="Enter Meta Description"
                    value={values.default_meta_description}
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors?.default_meta_description && touched.default_meta_description
                        ? "error"
                        : "correct"
                    }`}
                    rows={4}
                  />
                  {touched.default_meta_description && errors?.default_meta_description && (
                    <ValidationFeedback title={errors?.default_meta_description} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="default_meta_keyword"
                    title="Meta Keywords"
                  />
                  <TextArea
                    id="default_meta_keyword"
                    name="default_meta_keyword"
                    placeholder="Enter Meta Keywords"
                    onChange={handleChange}
                    value={values.default_meta_keyword}
                    className={`shadow-none ${
                      errors?.default_meta_keyword && touched.default_meta_keyword
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.default_meta_keyword && errors?.default_meta_keyword && (
                    <ValidationFeedback title={errors?.default_meta_keyword} />
                  )}
                </div>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-2 d-flex justify-content-center">
                Dynamic Meta Content
              </h4>
              <CommonFormLabel className="mb-4 ms-2 text-primary d-flex justify-content-center" htmlFor="note" title='(Note:- You can use "target_region" to place the selected Target Region in category description)'  />
                <div className="mb-4">
                  <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                  {/* <CommonFormLabel className="ms-2 text-primary" htmlFor="note" title='(Note:- You can use "target_region" to place the selected Target Region in category description)'  /> */}
                  <Input
                    id="meta_title"
                    name="meta_title"
                    type="text"
                    placeholder="Enter Meta Title"
                    onChange={handleChange}
                    value={values.meta_title}
                    className={`shadow-none ${
                      errors?.meta_title && touched.meta_title
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.meta_title && errors?.meta_title && (
                    <ValidationFeedback title={errors?.meta_title} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="meta_description"
                    title="Meta Description"
                  />
                  {/* <CommonFormLabel className="ms-2 text-primary" htmlFor="note" title='(Note:- You can use "target_region" to place the selected Target Region in category description)'  /> */}
                  <TextArea
                    id="meta_description"
                    name="meta_description"
                    placeholder="Enter Meta Description"
                    value={values.meta_description}
                    onChange={handleChange}
                    className={`shadow-none ${
                      errors?.meta_description && touched.meta_description
                        ? "error"
                        : "correct"
                    }`}
                    rows={4}
                  />
                  {touched.meta_description && errors?.meta_description && (
                    <ValidationFeedback title={errors?.meta_description} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="meta_keyword"
                    title="Meta Keywords"
                  />
                  <TextArea
                    id="meta_keyword"
                    name="meta_keyword"
                    placeholder="Enter Meta Keywords"
                    onChange={handleChange}
                    value={values.meta_keyword}
                    className={`shadow-none ${
                      errors?.meta_keyword && touched.meta_keyword
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.meta_keyword && errors?.meta_keyword && (
                    <ValidationFeedback title={errors?.meta_keyword} />
                  )}
                </div>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">
                Image Section
              </h4>
                <Row>
                  <Col md={7} className="mb-4">
                    <CommonFormLabel
                      htmlFor="category_image"
                      title="Upload New Image"
                    />
                    <FileInput
                      name="category_image"
                      //   onChange={(file) => setFieldValue("category_image", file)}
                      onChange={(file) => {
                        setFieldValue("category_image", file); // Update Formik's field value
                        if (file) {
                          setImagePreview(URL.createObjectURL(file)); // Generate preview for new image
                        }
                      }}
                      error={errors?.category_image}
                      touched={touched.category_image}
                    />
                    {touched.category_image && errors?.category_image && (
                      <ValidationFeedback title={errors?.category_image} />
                    )}
                  </Col>

                  <Col md={5} className="mb-4">
                    <CommonFormLabel htmlFor="image_alt" title="Image Alt" />
                    <Input
                      id="image_alt"
                      name="image_alt"
                      type="text"
                      placeholder="Enter Image Alt"
                      // onChange={handleChange}
                      onChange={(e) => {
                        setFieldValue("image_alt", e.target.value);
                      }}
                      value={values.image_alt}
                      className={`shadow-none ${
                        errors?.image_alt && touched.image_alt
                          ? "error"
                          : "correct"
                      }`}
                    />
                    {touched.image_alt && errors?.image_alt && (
                      <ValidationFeedback title={errors?.image_alt} />
                    )}
                  </Col>
                </Row>

                <Row>
                  {imagePreview && (
                    <Col md={6} className="mb-4">
                      <CommonFormLabel
                        htmlFor="new_category_image"
                        title="New Selected Image"
                      />
                      <div className="mt-2">
                        <img
                          src={imagePreview}
                          alt="New Selected Category"
                          // className="img-fluid rounded border"
                          style={{
                            width: "200px",
                            height: "auto",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </Col>
                  )}
                  <Col md={6} className="">
                    <CommonFormLabel
                      htmlFor="old_category_image"
                      title="Uploaded Image"
                    />
                    <div className="mt-2">
                      <img
                        src={category.category_image}
                        alt="Category"
                        className=""
                        style={{
                          width: "200px",
                          height: "auto",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Col>
                </Row>

                <hr />
              {/* </Card>

              

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4"> */}
                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="category_banner_image"
                    title="Upload New Banner Image"
                  />
                  <FileInput
                    name="category_banner_image"
                    // onChange={(file) =>
                    //   setFieldValue("category_banner_image", file)
                    // }
                    onChange={(file) => {
                      setFieldValue("category_banner_image", file); // Update Formik's field value
                      if (file) {
                        setBannerImagePreview(URL.createObjectURL(file)); // Generate preview for new image
                      }
                    }}
                    error={errors?.category_banner_image}
                    touched={touched.category_banner_image}
                  />
                  {touched.category_banner_image &&
                    errors?.category_banner_image && (
                      <ValidationFeedback
                        title={errors?.category_banner_image}
                      />
                    )}
                </div>

                <Row>
                  {bannerImagePreview && (
                    <Col md={6} className="mb-4">
                      <CommonFormLabel
                        htmlFor="new_category_banner_image"
                        title="New Selected Banner Image"
                      />
                      <div className="mt-2">
                        <img
                          src={bannerImagePreview}
                          alt="New Selected Category"
                          className="img-fluid rounded border"
                          style={{
                            width: "200px",
                            height: "auto",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    </Col>
                  )}
                  <Col md={6} className="">
                    <CommonFormLabel
                      htmlFor="old_category_image"
                      title="Uploaded Banner Image"
                    />
                    <div className="mt-2">
                      <img
                        src={category.category_banner_image}
                        alt="Category"
                        className=""
                        style={{
                          width: "200px",
                          height: "auto",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">
                Brochure
              </h4>
                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="brochure_file"
                    title="Upload Brochure"
                  />
                  <FileInput
                    name="brochure_file"
                    // onChange={(file) => setFieldValue("brochure_file", file)}
                    onChange={(file) => {
                      setFieldValue("brochure_file", file); // Update Formik's field value
                      if (file) {
                        setBrochurePath(URL.createObjectURL(file)); // Generate preview for new image
                      }
                    }}
                    error={errors?.brochure_file}
                    touched={touched.brochure_file}
                  />
                  {touched.brochure_file && errors?.brochure_file && (
                    <ValidationFeedback title={errors?.brochure_file} />
                  )}
                </div>

                <Row>
                  {brochurePath && (
                    <Col md={6} className="">
                      <CommonFormLabel
                        htmlFor="brochure_file"
                        title="New Selected Brochure"
                      />
                      <div className="mt-2">
                        <a
                          href={brochurePath}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <p>View New Selected Banner</p>
                        </a>
                      </div>
                    </Col>
                  )}

                  <Col md={6} className="">
                    <CommonFormLabel
                      htmlFor="brochure_file"
                      title="Uploaded Brochure"
                    />
                    <div className="mt-2">
                      <a
                        href={category.brochure_file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{category.brochure_file}</p>
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">
                Manage Attribute
              </h4>
                {/* {category && category.parent_category_id !== null && ( */}
                {/* <div className="mb-4 text-center common-popup-heading">
                <h4 className="text-capitalize mb-2 fw-medium">
                  Category Attributes
                </h4>
              </div> */}
                {/* )} */}
                {/* {category && category.parent_category_id === null && ( */}

                <div className="mb-4">
                  {attributes.map((attribute, index) => (
                    <div key={attribute.id} className="mb-4">
                      <CommonFormLabel
                        htmlFor={`attributes ${index + 1}`}
                        title={`Attribute ${index + 1}`}
                      />
                      <Input
                        type="text"
                        placeholder={`Attribute ${index + 1} Name`}
                        value={attribute.name}
                        onChange={handleAttributeChange(attribute.id, "name")}
                        required={true}
                      />
                      <div className="mt-2">
                        {attribute.values.map((value, valueIndex) => (
                          <div
                            key={valueIndex}
                            className="d-flex align-items-center"
                          >
                            <Input
                              type="text"
                              placeholder={`Value ${valueIndex + 1}`}
                              value={value}
                              onChange={handleAttributeChange(
                                attribute.id,
                                "values",
                                valueIndex
                              )}
                              className="me-5"
                              required={true}
                            />
                            <button
                              type="button"
                              className="btn btn-danger ml-2"
                              onClick={() =>
                                removeAttributeValue(attribute.id, valueIndex)
                              }
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                        <CommonButton
                          title="Add Value"
                          type="button"
                          onClick={() => addAttributeValue(attribute.id)}
                          disabled={isSubmitting}
                          className="btn-primary primary-shadow mt-2"
                        />
                        <button
                          type="button"
                          className="btn btn-danger mt-2 ms-2"
                          onClick={() => removeAttribute(attribute.id)}
                        >
                          Remove Attribute
                        </button>
                      </div>
                      <hr className="my-5" />
                    </div>
                  ))}
                  <CommonButton
                    title="Add Attribute"
                    type="button"
                    onClick={addAttribute}
                    disabled={isSubmitting}
                    className="btn-primary primary-shadow"
                  />
                </div>
                {/* )} */}
              </Card>

              <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
                <CommonButton
                  title={isSubmitting ? "Updating..." : "Update"}
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommonButton
                  title="Cancel"
                  onClick={handleCancel}
                  className="btn-muted"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
