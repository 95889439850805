// import React from 'react';
// // import { useNavigate } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux'; // Assuming you're using Redux to manage auth

// const PrivateRoute = ({ children }) => {
//   // const navigate = useNavigate();


//   const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

//   return isAuthenticated ? children : <Navigate to="/login" replace />;

//   // return (
//   //   <Route
//   //     {...rest}
//   //     render={(props) =>
//   //       isAuthenticated ? (
//   //         <Component {...props} />
//   //       ) : (
//   //         // <Redirect to="/login" />
//   //         navigate("/login", { replace: true })
//   //       )
//   //     }
//   //   />
//   // );
// };

// export default PrivateRoute;



// import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux'
// import { useNavigate } from "react-router-dom";
// import { userDetails } from "../modules/Auth/authSlice";
// import { Spinner } from "react-bootstrap";
// import { useAuth } from "../context/AuthContext";

// const ProtectedRoute = ({ children }) => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const { isAuthenticated } = useAuth();

//     useEffect(() => {
//         const checkAuthenticated = async () => {
//             dispatch(userDetails()).then(res => {
//                 if (res.payload === undefined) {
//                     navigate('/');
//                 } else {
//                     if (res.payload.data.success === false) {
//                         navigate('/');
//                     }
//                 }  
//             });
//         }

//         checkAuthenticated();
//     }, [dispatch,navigate]);

//     // return (isAuthenticated === true) ? children : <div className="loading show"> <Spinner className="loader" animation="grow" variant="primary" /> </div>;
//     return (isAuthenticated === true) ? children : <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 9999999, height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)" }}>
//     <Spinner animation="border" role="status" variant="light">
//         <span className="visually-hidden">Loading...</span>
//     </Spinner>
//     </div>;
// };

// export default ProtectedRoute;


import React from 'react';
import { Navigate } from "react-router-dom";
// import { useAuth } from "../context/AuthContext";
import { Spinner } from "react-bootstrap";

const PrivateRoute = ({ children }) => {
  // const { isAuthenticated } = useAuth();
  const token = localStorage.getItem('auth-token');
  const isAuthenticated = token !== null ? true : false;
  // const isAuthenticated  = true;


  // If the authentication state is still loading, you can display a loader or spinner.
  if (isAuthenticated === undefined) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 9999999, height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)" }}>
        <Spinner animation="border" role="status" variant="light">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // If the user is authenticated, render the children (protected route content)
  // If not, redirect to the login page
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
