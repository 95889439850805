import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInquiries,
  deleteInquiry,
  changeActiveStatus,
} from "./inquirySlice";
import { Container, Modal, Toast, Button } from "react-bootstrap";
import Input from "../../components/input";
import CommonPagination from "../../components/commonPagination";
import CommonTable from "../../components/commonTable";
import { useLoader } from "../../context/LoaderContext";
import { Tab, Tabs } from "react-bootstrap";

const InquiryList = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { inquiries } = useSelector((state) => state.inquiries);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  //   const [showModal, setShowModal] = useState(false);
  const [selectedInquiryId, setSelectedInquiryId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [tabValue, setTabValue] = useState("all");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const limit = 10;
  const totalPages = Math.ceil(inquiries?.total_records / limit);
  const total = inquiries?.total_records || 0;

  const token = localStorage.getItem("auth-token");

  useEffect(() => {
    const fetchInquiries = async () => {
      setLoading(true);
      const payload = {
        page: currentPage,
        search: searchTerm,
        limit: limit,
        is_active: tabValue,
        token,
      };

      try {
        if (token) {
          await dispatch(getInquiries({ payload }));
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching inquiries:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInquiries();
  }, [dispatch, token, currentPage, searchTerm, setLoading, tabValue]);

  const handleTabChange = (key) => {
    setTabValue(key);
    setCurrentPage(1); // Reset to first page when tab changes
  };

  const columns = [
    { label: "User Info", key: "info", type: "custom" },
    // { label: "Contact Name", key: "contact_name" },
    // { label: "Contact Number", key: "contact_no" },
    // { label: "Contact Email", key: "contact_email" },
    { label: "Contact Message", key: "contact_message" },
    // { label: "Category", key: "category.category_name" },
    // { label: "Product", key: "product.product_name" },
    // { label: "Country", key: "country.name" },
    { label: "created at", key: "created_at", type: "date" },
    { label: "Status", key: "is_active", type: "status_enquiry" },
  ];

  const handlePageChange = (newPageNumber) => {
    setCurrentPage(newPageNumber);
  };

  const handleDeleteNew = async () => {
    if (selectedInquiryId) {
      await dispatch(deleteInquiry(selectedInquiryId)).then((res) => {
        if (res.payload.success) {
          setShowDeleteModal(false);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            is_active: tabValue,
            token,
          };
          dispatch(getInquiries({ payload }));
        } else {
          setShowDeleteModal(false);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
        }
      });
    }
  };

  const handleEdit = (row) => {
    setSelectedInquiryId(row.id);
    // handleOpen();
  };

  // const handleOpen = () => {
  //   setTimeout(() => {
  //     setShowEditModal(true);
  //   }, 1000);
  // };

  const handleDelete = (row) => {
    setSelectedInquiryId(row.id);
    setShowDeleteModal(true);
  };

  const handleView = (row) => {
    setSelectedInquiryId(row.id);
    // setShowViewModal(true);
  };

  const handleStatusChange = async (row) => {
    setSelectedInquiryId(row.id);
    setShowStatusModal(true);
  };

  const handleStatusChangeNew = async () => {
    await dispatch(changeActiveStatus(selectedInquiryId)).then((res) => {
      if (res.payload.success) {
        setShowStatusModal(false);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
        const payload = {
          page: currentPage,
          search: searchTerm,
          is_active: tabValue,
          limit: limit,
          token,
        };
        dispatch(getInquiries({ payload }));
      } else {
        setShowStatusModal(false);
        setSnackbarMessage(res.payload.message);
        setSnackbarVariant("danger");
        setShowSnackbar(true);
      }
    });
  };

  return (
    <>
      <Container fluid className="px-0">
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this inquiry?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteNew}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Status Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to change status?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowStatusModal(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleStatusChangeNew}>
              Change
            </Button>
          </Modal.Footer>
        </Modal>

        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
        >
          <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
        </Toast>

        <div className="p-4 d-flex align-items-center justify-content-between gap-4">
          <Input
            type="search"
            value={searchTerm}
            placeholder="Search Inquiry"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
            className="shadow-none base-search"
          />
        </div>
        <Tabs
          id="example-tabs"
          // activeKey={activeKey}
          // onSelect={(key) => setActiveKey(key)}
          activeKey={tabValue}
          onSelect={handleTabChange}
          className="mb-3"
        >
          {/* All Tabs */}
          <Tab eventKey="all" title="All">
            <div className="bg-white rounded-2 overflow-hidden common-shadow">
              <CommonTable
                columns={columns}
                data={inquiries?.data || []}
                url={inquiries?.url || []}
                showActions={true}
                actions={{
                  showEdit: false,
                  showDelete: true,
                  showView: false,
                  onEdit: handleEdit,
                  onDelete: handleDelete,
                  onView: handleView,
                }}
                onStatusChange={handleStatusChange}
              />
            </div>
          </Tab>

          {/* Active Tab */}
          <Tab eventKey="active" title="Open">
            <div className="bg-white rounded-2 overflow-hidden common-shadow">
              <CommonTable
                columns={columns}
                data={inquiries?.data || []}
                url={inquiries?.url || []}
                showActions={true}
                actions={{
                  showEdit: false,
                  showDelete: true,
                  showView: false,
                  onEdit: handleEdit,
                  onDelete: handleDelete,
                  onView: handleView,
                }}
                onStatusChange={handleStatusChange}
              />
            </div>
          </Tab>

          {/* Deactive Tab */}
          <Tab eventKey="deactive" title="Closed">
            <div className="bg-white rounded-2 overflow-hidden common-shadow">
              <CommonTable
                columns={columns}
                data={inquiries?.data || []}
                url={inquiries?.url || []}
                showActions={true}
                actions={{
                  showEdit: false,
                  showDelete: true,
                  showView: false,
                  onEdit: handleEdit,
                  onDelete: handleDelete,
                  onView: handleView,
                }}
                onStatusChange={handleStatusChange}
              />
            </div>
          </Tab>
        </Tabs>
        {/* <div className="bg-white rounded-2 overflow-hidden common-shadow">
          <CommonTable
            columns={columns}
            data={inquiries?.data || []}
            url={inquiries?.url || []}
            showActions={true}
            actions={{
              showEdit: false,
              showDelete: true,
              showView: false,
              onEdit: handleEdit,
              onDelete: handleDelete,
              onView: handleView,
            }}
            onStatusChange={handleStatusChange}
          />
        </div> */}

        <div className="p-3 mx-1 d-flex flex-wrap align-items-center justify-content-between gap-4">
          <p className="custom-table-infotext">
            Displaying {limit * (currentPage - 1) + 1} to{" "}
            {Math.min(limit * currentPage, total)} of {total} Inquiries
          </p>

          <div>
            {totalPages > 1 ? (
              <CommonPagination
                totalItems={total}
                itemsPerPage={limit}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </>
  );
};

export default InquiryList;
