import React, { useState } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { Toast, Form, Col, Row, Card } from "react-bootstrap";
import { Formik } from "formik";
import { addGallery } from "./gallerySlice";
import { gallerySchema } from "../../schema/validationSchemas";

export default function Add() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  const initialEventState = {
    gallery_title: "",
    gallery_image: "",
  };

  const handleModalClose = () => {
    navigate("/galleries");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setFieldValue("gallery_image", file);
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    await dispatch(addGallery(values))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("gallery_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          navigate("/galleries");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message || "Failed to add gallery!");
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add gallery!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Formik
        initialValues={initialEventState}
        validationSchema={gallerySchema}
        onSubmit={handleSubmit}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} encType="multipart/form-data" className="bg-white p-4 rounded-2 shadow-sm">
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
            <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
            <Row>
              <Col md={12} className="mb-4">
                <CommonFormLabel
                  htmlFor="gallery_title"
                  title="Gallery Title"
                />
                <Input
                  id="gallery_title"
                  name="gallery_title"
                  type="text"
                  placeholder="Enter Gallery Title"
                  onChange={handleChange}
                  className={`shadow-none ${
                    errors?.gallery_title && touched.gallery_title
                      ? "error"
                      : "correct"
                  }`}
                />
                {touched.gallery_title && errors?.gallery_title && (
                  <ValidationFeedback title={errors?.gallery_title} />
                )}
              </Col>
            </Row>
            </Card>

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
            <h4 className="mb-4 d-flex justify-content-center">Image</h4>
            <div className="mb-4">
              <CommonFormLabel htmlFor="gallery_image" title="Upload Image" />
              <FileInput
                name="gallery_image"
                onChange={(file) => handleImageChange(file, setFieldValue)}
                error={errors?.gallery_image}
                touched={touched.gallery_image}
              />
              {touched.gallery_image && errors?.gallery_image && (
                <ValidationFeedback title={errors?.gallery_image} />
              )}
              {imagePreview && (
                  <div className="mt-3">
                    <img
                      src={imagePreview}
                      alt="Blog Preview"
                      style={{
                        width: "200px",
                        height: "auto",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                )}
            </div>
            </Card>

            <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
              <CommonButton
                title={isSubmitting ? "Adding..." : "Add"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary primary-shadow"
              />
              <CommonButton
                title="Cancel"
                onClick={handleModalClose}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
