import React, { useEffect, useState, useCallback } from "react";
import { Modal, Toast, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getCategoryDetails } from "../../modules/Category/categorySlice"; // Assuming you have this API call
import { Cancel01Icon } from "../../assets/icons";

export default function CategoryView({
  show,
  handleClose,
  categoryId,
}) {
  const [category, setCategory] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const dispatch = useDispatch();

  const fetchCategory = useCallback(async () => {
    try {
      const result = await dispatch(getCategoryDetails(categoryId));
      setCategory(result.payload.data);
    } catch (error) {
      setSnackbarMessage("Failed to load category details!");
      setSnackbarVariant("danger");
      setShowSnackbar(true);
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (categoryId && show) {
      fetchCategory(); // Fetch data only when modal is opened
    }
  }, [categoryId, show, fetchCategory]);

  const handleModalClose = useCallback(() => {
    setCategory(null);
    handleClose();
  }, [handleClose]);

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        <Modal.Body className="common-popup-body">
          <div
            className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center common-popup-close-btn"
            onClick={handleModalClose}
          >
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">View Category</h4>
            <p>Category details</p>
          </div>

          {category ? (
            <div>
              <Row>
                <Col md={6} className="mb-4">
                  <strong>Category Name:</strong>
                  <p>{category.category_name}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Parent Category Name:</strong>
                  <p>{category?.parent_category?.category_name || '-'}</p>
                </Col>
              </Row>


              <hr></hr>

              <Row>
                <Col md={6} className="mb-4">
                  <strong>H2 Title Tag:</strong>
                  <p>{category.h2_title_tag}</p>
                </Col>

                <Col md={6} className="mb-4">
                  <strong>Video Link:</strong>
                  <p>{category.category_video_link}</p>
                </Col>
              </Row>

              <hr></hr>

              <div className="mb-4">
                <strong>Category Description:</strong>
                
                 <div
                  dangerouslySetInnerHTML={{ __html: category.category_description }}
                />
              </div>

              <hr></hr>

              <div className="mb-4">
                <strong>Dynamic Description:</strong>
                <div
                  dangerouslySetInnerHTML={{ __html: category.default_category_description }}
                />
              </div>
              
              <hr></hr>

              <div className="mb-4">
                <strong>Meta Title:</strong>
                <p>{category.meta_title}</p>
              </div>

              <hr></hr>

              <div className="mb-4">
                <strong>Meta Description:</strong>
                <p>{category.meta_description}</p>
              </div>

              <hr></hr>

              <div className="mb-4">
                <strong>Meta Keywords:</strong>
                <p>{category.meta_keyword}</p>
              </div>

              <hr></hr>

              {category.category_image && (
                <Row>
                  <Col md={8} className="mb-4">
                    <strong>Category Image:</strong>
                    <div className="mt-2">
                      <img src={category.category_image} alt="Category" className="" height={"100px"} width={"100px"} />
                    </div>
                  </Col>
                  <Col md={4} className="mb-4">
                  <strong>Image Alt</strong>
                    <div className="mt-2">
                      <p>{category.image_alt}</p>
                    </div>
                  </Col>
                </Row>
              )}

              <hr></hr>

              {category.category_banner_image && (
                <div className="mb-4">
                  <strong>Banner Image:</strong>
                  <div className="mt-2">
                    <img src={category.category_banner_image} alt="Banner" className="" height={"100px"} width={"100px"} />
                  </div>
                </div>
              )}

              <hr></hr>

              {category.brochure_file && (
                <div className="mb-4">
                  <strong>Brochure File:</strong>
                  <div className="mt-2">
                    <a href={category.brochure_file} target="_blank" rel="noopener noreferrer">
                      <p>{category.brochure_file}</p>
                    </a>
                  </div>
                </div>
              )}

              <hr></hr>

              {category.attribute_groups && category.attribute_groups.length > 0 && (
                <div className="mb-4">
                  <hr></hr>
                  <strong>Attributes: </strong>
                  <div>
                    <ul>
                      {category.attribute_groups.map((attribute, index) => (
                        <li key={index} className="my-4">
                          <div className="d-flex align-items-center mb-3">
                          <h6>Attribute {index + 1}</h6>
                          </div>
                          <div key={index} className="d-flex align-items-center">
                            <h6>Attribute Name: </h6> 
                              <span> {index > 0 && "   "}</span> <div>&nbsp;</div>
                              {attribute.attribute_group_name}
                          </div>
                          <div className="d-flex align-items-center">
                          <h6>Attribute Values: </h6> 
                          {attribute?.attribute_groups_values?.map((value, valueIndex) => (
                            <div key={valueIndex} className="d-flex align-items-center">
                              <span> {valueIndex > 0 && ",   "}</span> <div>&nbsp;</div>
                              <span  key={valueIndex}>{value.attribute_group_value}</span>
                            </div>
                          ))}
                          </div>
                          <hr className="me-5"></hr>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p>Loading category details...</p> // Show loading message while fetching data
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
