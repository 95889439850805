import { apiHelper } from '../utils/apiHelper';
const API_URL = `${process.env.REACT_APP_API_URL}/api/auth`;

// import axios from 'axios';

// export const loginApi = async (credentials) => {
//   const response = await axios.post('https://api.example.com/login', credentials);
//   return response.data;
// };

export const loginApi = (payload) => apiHelper(`${API_URL}/login`, 'POST', payload);
export const logOutApi = (payload) => apiHelper(`${API_URL}/logout`, 'GET', payload);

export const registerUserApi = (payload) => apiHelper(`${API_URL}/register`, 'POST', payload);

export const userDetailsApi = (payload, token) => apiHelper(`${API_URL}/me`, 'GET', payload, token);

