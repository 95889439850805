import React from "react";
import { Dropdown } from "react-bootstrap";
import "./commonDropdown.css";

export default function CommonActionDropDown({
  isOpen,
  onToggle,
  icon,
  firstTitle,
  firstHref,
  firstOnClick,
  secondTitle,
  secondHref,
  secondOnClick,
  thirdTitle,  // New prop for delete action
  thirdHref,
  thirdOnClick
}) {
  return (
    <Dropdown
      align="end"
      className="position-static common-action-btn"
      show={isOpen} 
      onToggle={onToggle}
    >
      <Dropdown.Toggle
        variant="none"
        className="outline-none border-0 p-0 d-flex align-items-center justify-content-center drop-btn"
        id="dropdown-basic"
      >
        {icon}
      </Dropdown.Toggle>

      <Dropdown.Menu className="border-0 p-2 rounded-2 z-1 action-drop-menu">
        {firstTitle && (
          <Dropdown.Item
            href={firstHref}
            onClick={(e) => {
              e.stopPropagation();
              firstOnClick && firstOnClick(e);
            }}
            className="text-capitalize rounded-2 fw-normal action-drop-item"
          >
            {firstTitle}
          </Dropdown.Item>
        )}
        {secondTitle && (
          <Dropdown.Item
            href={secondHref}
            onClick={(e) => {
              e.stopPropagation();
              secondOnClick && secondOnClick(e);
            }}
            className="text-capitalize rounded-2 fw-normal action-drop-item"
          >
            {secondTitle}
          </Dropdown.Item>
        )}
        {thirdTitle && (
          <Dropdown.Item
            href={thirdHref}
            onClick={(e) => {
              e.stopPropagation();
              thirdOnClick && thirdOnClick(e);
            }}
            className="text-danger rounded-2 fw-normal action-drop-item"
          >
            {thirdTitle}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
