import React, { useState, useEffect } from "react";
import useMounted from "../../hooks/useMounted";
// import dummyUser from "../../assets/images/navigation-bar/dummy-user.png"
import {
  Dropdown,
  // Image,
  Button,
  ToastContainer,
  Toast,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logOut } from "../../modules/Auth/authSlice";
import {
  // UserStoryIcon,
  Logout03Icon,
  CheckmarkBadge04Icon,
} from "../../assets/icons";
import "./navbar.css";

export default function NavigationBar() {
  const location = useLocation();

  const getUserInitial = (name) => {
    return name
      ? name
          .split(" ")
          .map((n) => n.charAt(0))
          .join("")
      : "UN";
  };

  const hasMounted = useMounted();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [title, setTitle] = useState('dashboard');
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");

  const user = localStorage.getItem("userData");
  let userData = null;
  if (user) {
    userData = JSON.parse(user);
  }

  useEffect(() => {
    let title = "dashboard"; // Default title
  
    if (location.pathname === "/") {
      title = "dashboard";
    } else if (location.pathname === "/blogs") {
      title = "blogs";
    } else if (location.pathname === "/blog-add") {
      title = "add New blog";
    } else if (location.pathname.startsWith("/blog-edit/")) {
      title = "Edit blog";
    } else if (location.pathname === "/tags") {
      title = "tags";
    } else if (location.pathname === "/tag-add") {
      title = "add New tag";
    } else if (location.pathname.startsWith("/tag-edit/")) {
      title = "Edit tag";
    } else if (location.pathname === "/target-regions") {
      title = "Target Regions";
    } else if (location.pathname === "/galleries") {
      title = "gallery";
    } else if (location.pathname === "/gallery-add") {
      title = "add New gallery";
    } else if (location.pathname.startsWith("/gallery-edit/")) {
      title = "Edit gallery";
    // } else if (location.pathname === "/settings") {
    //   title = "settings";
    } else if (location.pathname === "/categories") {
      title = "categories";
    } else if (location.pathname === "/category-add") {
      title = "add New category";
    } else if (location.pathname.startsWith("/category-edit/")) {
      title = "Edit category";
    } else if (location.pathname === "/products") {
      title = "products";
    } else if (location.pathname === "/product-add") {
      title = "add New product";
    } else if (location.pathname.startsWith("/product-edit/")) {
      title = "Edit product";
    }  else if (location.pathname === "/testimonials") {
      title = "Testimonials";
    } else if (location.pathname === "/testimonial-add") {
      title = "add New testimonial";
    } else if (location.pathname.startsWith("/testimonial-edit/")) {
      title = "Edit testimonial";
    } else if (location.pathname === "/teams") {
      title = "Teams";
    } else if (location.pathname === "/team-add") {
      title = "add New team";
    } else if (location.pathname.startsWith("/team-edit/")) {
      title = "Edit team";
    } else if (location.pathname === "/inquiries") {
      title = "Inquiries";
    } else if (location.pathname === "/home-page") {
      title = "Home Page Page";
    } else if (location.pathname === "/about-us") {
      title = "About Us Page";
    } else if (location.pathname === "/privacy-policy") {
      title = "Privacy Policy Page";
    } else if (location.pathname === "/terms-conditions") {
      title = "Terms & Conditions Page";
    } else if (location.pathname === "/general") {
      title = "General Setting Page";
    } else if (location.pathname === "/seo-configuration") {
      title = "SEO Configuration Page";
    } else {
      title = "Not Found";
    }
  
    setTitle(title);
  }, [location.pathname]);

  const handleLogOut = async () => {
    try {
      await dispatch(logOut()).then((res) => {
        localStorage.removeItem("auth-token");
        if(res.success) {
          setToastMessage(res.message || "Logout successful");
          setToastVariant("toast-success");
          setShowToast(true);
          setTimeout(() => {
            if (hasMounted) {
              navigate("/login", { replace: true });
            }
          }, 2000);
        }
      });

      setToastMessage("Logout successful");
        setToastVariant("toast-success");
        navigate("/login", { replace: true });
    } catch (error) {
      console.error("Logout failed:", error);
      setToastMessage("Logout failed");
      setToastVariant("toast-danger");
      setShowToast(true);
      setTimeout(() => {
        if (hasMounted) {
          navigate("/login", { replace: true });
        }
      }, 2000);
    } finally {
      setTimeout(() => {
        if (hasMounted) {
          navigate("/login", { replace: true });
        }
      }, 2000);
    }
  };

  // const getUserInitial = (name) => {
  //   return name ? name.charAt(0).toUpperCase() : "U";
  // };

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="px-4 py-2 bg-white position-sticky custom-navigationbar">
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="fw-semibold text-capitalize pg-title">{title}</h5>

          <Dropdown className="profile-menu" align="end">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-basic"
              className="position-relative p-0 profile-toggle"
            >
              
              {/* <Image
                src={dummyUser}
                className="h-100 w-100 d-inline-block"
                alt={`${userData?.data?.name} Image`}
                roundedCircle
              /> */}
              <div className="rounded-circle d-flex align-items-center justify-content-center user-profile-icon">
                {getUserInitial(userData?.data?.name)}
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="mt-4 p-2 profile-submenu">
              <Dropdown.Item
                href="#/action-1"
                className="py-2 px-3 profile-menu-item"
              >
                <div className="d-flex align-items-center gap-2">
                  <div className="position-relative flex-shrink-1 profile-user">
                    {/* <Image
                      src={dummyUser}
                      className="h-100 w-100 d-inline-block"
                      alt={`${userData?.data?.name} Image`}
                      roundedCircle
                    /> */}
                    <div className="rounded-circle d-flex align-items-center justify-content-center user-profile-icon">
                      {getUserInitial(userData?.data?.name)}
                    </div>
                  </div>
                  <div className="overflow-hidden flex-grow-1">
                    <h6 className="fw-medium text-capitalize mb-0">
                      {userData?.data?.name}
                    </h6>
                    <div className="text-capitalize post-tag">{userData?.data?.company_name}</div>
                  </div>
                </div>
              </Dropdown.Item>
              <div className="profile-submenu-divider"></div>
              {/* <Dropdown.Item
                href="/profile"
                className="py-2 px-3 profile-menu-item"
              >
                <div className="d-flex align-items-center gap-2">
                  <UserStoryIcon
                    width={19}
                    height={19}
                    color="#444050"
                    strokeWidth="2"
                  />
                  <span className="ms-1 text-capitalize fw-normal">
                    My Profile
                  </span>
                </div>
              </Dropdown.Item> */}
              <div className="profile-submenu-divider"></div>
              <Dropdown.Item href="#" className="pt-2 pb-1 px-3  logout-btn">
                <Button
                  variant="link"
                  onClick={handleLogOut}
                  className="w-100 text-white d-flex align-items-center justify-content-center text-decoration-none gap-2"
                >
                  Logout
                  <span>
                    <Logout03Icon
                      width={15}
                      height={15}
                      color="#ffffff"
                      strokeWidth="2"
                    />
                  </span>
                </Button>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
}
