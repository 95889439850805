import React, { useEffect, useRef, useState } from "react";
import SidebarItem from "./sidebar-item";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { SidebarContent, ConfigurationContent } from "./sidebar-content";
// import logoBlack from "../../assets/images/logo/logo-dark.png";
import { SettingIcon, ArrowRight02Icon2 } from "../../assets/icons";
import { getSettings } from '../../modules/Settings/settingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLoader } from "../../context/LoaderContext";
import "./sidebar.css";

export default function Sidebar({ className }) {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { settings } = useSelector((state) => state.settings);
  const [scrolled, setScrolled] = useState(false);
  const sidebarRef = useRef(null);

  const location = useLocation();

  const handleSidebarScroll = () => {
    const scrollPosition = sidebarRef.current.scrollTop;

    if (scrollPosition > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const sidebar = sidebarRef.current;
    if (sidebar) {
      sidebar.addEventListener("scroll", handleSidebarScroll);
    }
    return () => {
      if (sidebar) {
        sidebar.removeEventListener("scroll", handleSidebarScroll);
      }
    };
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      setLoading(true);
      try {
        await dispatch(getSettings());
      } catch (error) {
        setLoading(false);
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, [dispatch, setLoading]);


  const isActive = (path) => {
    if ((location.pathname === "/blog-add" || location.pathname.startsWith("/blog-edit/")) && path === "/blogs") {
      return true;
    }
    if ((location.pathname === "/tag-add" || location.pathname.startsWith("/tag-edit/")) && path === "/tags") {
      return true;
    }
    if ((location.pathname === "/category-add" || location.pathname.startsWith("/category-edit/")) && path === "/categories") {
      return true;
    }
    if ((location.pathname === "/product-add" || location.pathname.startsWith("/product-edit/")) && path === "/products") {
      return true;
    }

    if ((location.pathname === "/gallery-add" || location.pathname.startsWith("/gallery-edit/")) && path === "/galleries") {
      return true;
    }

    if ((location.pathname === "/testimonial-add" || location.pathname.startsWith("/testimonial-edit/")) && path === "/testimonials") {
      return true;
    }

    if ((location.pathname === "/team-add" || location.pathname.startsWith("/team-edit/")) && path === "/teams") {
      return true;
    }
    return location.pathname === path;
  };

  return (
    <>
      <aside className={`bg-white ${className} sidebar`}>
        <div className="h-100 position-relative d-flex flex-column">
          <div className="flex-shrik-1 position-relative mt-3 mb-2">
            <div
              className={`d-flex align-items-center justify-content-center position-sticky top-0 start-0 end-0 site-logo ${
                scrolled ? "effect-logo" : null
              }`}
            >
              <Link to="/dashboard">
                <h1>
                  <Image 
                    src={settings?.logo}
                    alt="Logo"
                    style={{
                      maxWidth: "130px",
                      maxHeight: "100px",
                    }}
                  />
                </h1>
              </Link>
            </div>
          </div>
          <div className="py-1 flex-grow-1 overflow-y-auto" ref={sidebarRef}>
            <ul className="mb-0 list-unstyled">
              {SidebarContent.map((item, i) => {
                return (
                  <SidebarItem
                    key={i}
                    to={item.link}
                    icon={item.icon}
                    title={item.title}
                    className={
                      isActive(item.link) === true ? "active" : null
                    }
                  />
                );
              })}
            </ul>
            <ul className="mb-0 list-unstyled mt-2">
              <SidebarItem
                to="#"
                icon={<SettingIcon width={18} height={18} color="#444050" strokeWidth="2" />}
                dropdownIcon={<ArrowRight02Icon2 width={18} height={18} color="#444050" strokeWidth="2" />}
                title="Configuration"
                children={ConfigurationContent}
              />
            </ul>
          </div>
        </div>
      </aside>
    </>
  );
}
