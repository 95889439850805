import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSeoContents, updateSeoContents } from "../../api/seoContentApi";

export const getSeoContents = createAsyncThunk("seoContent/getSeoContents", async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchSeoContents(payload);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching sections:", error);
  }
});

export const updateSeoContent = createAsyncThunk("seoContent/updateSeoContent", async (payload, { rejectWithValue }) => {
    try {
      const response = await updateSeoContents(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const SeoContentPageSlice = createSlice({
  name: "seoContent",
  initialState: {
    seoContents: {},
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeoContents.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSeoContents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seoContents = action.payload;
      })
      .addCase(getSeoContents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default SeoContentPageSlice.reducer;
