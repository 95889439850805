import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import { Card, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import {
  updateGallery,
  getGalleryDetails,
} from "../../modules/Gallery/gallerySlice";
import { galleryUpdateSchema } from "../../schema/validationSchemas";

export default function Edit() {
  const { id: galleryId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const dispatch = useDispatch();

  const [gallery, setGallery] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImagePreview, setNewImagePreview] = useState(null);

  const fetchGallery = useCallback(async () => {
    setLoading(true);
    try {
      const result = await dispatch(getGalleryDetails(galleryId));
      setGallery(result.payload.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching gallery:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, galleryId, setLoading]);

  useEffect(() => {
    setGallery(null);
    if (galleryId) {
      fetchGallery();
    }
  }, [galleryId, fetchGallery]);

  const handleCancel = () => {
    setGallery(null);
    navigate("/galleries");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setNewImagePreview(URL.createObjectURL(file));
      setFieldValue("gallery_image", file);
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    const updatedValues = { ...values, id: galleryId };

    const formData = new FormData();
    formData.append("id", updatedValues.id);
    formData.append("_method", "PUT");
    formData.append("gallery_title", updatedValues.gallery_title);
    formData.append("gallery_image", updatedValues.gallery_image);

    await dispatch(updateGallery(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("gallery_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          setGallery(null);
          navigate("/galleries");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(
            res.payload.message || "Failed to update gallery!"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update gallery!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  if (!gallery)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Gallery Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      {gallery && (
        <Formik
          initialValues={{
            gallery_title: gallery.gallery_title || "",
            gallery_image: "",
          }}
          validationSchema={galleryUpdateSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="bg-white p-4 rounded-2 shadow-sm"
            >
              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
                <Row>
                  <Col md={12} className="mb-4">
                    <CommonFormLabel
                      htmlFor="gallery_title"
                      title="Gallery Title"
                    />
                    <Input
                      id="gallery_title"
                      name="gallery_title"
                      type="text"
                      placeholder="Enter Gallery Title"
                      onChange={handleChange}
                      value={values.gallery_title}
                      className={`shadow-none ${
                        errors?.gallery_title && touched.gallery_title
                          ? "error"
                          : "correct"
                      }`}
                    />
                    {touched.gallery_title && errors?.gallery_title && (
                      <ValidationFeedback title={errors?.gallery_title} />
                    )}
                  </Col>
                </Row>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <h4 className="mb-4 d-flex justify-content-center">Image</h4>
                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="gallery_image"
                    title="Upload New Image"
                  />
                  <FileInput
                    name="gallery_image"
                    onChange={(file) => handleImageChange(file, setFieldValue)}
                    error={errors?.gallery_image}
                    touched={touched.gallery_image}
                  />
                  {touched.gallery_image && errors?.gallery_image && (
                    <ValidationFeedback title={errors?.gallery_image} />
                  )}
                </div>

                <Row>
                  {newImagePreview && (
                    <Col md={6} className="">
                      <CommonFormLabel
                        htmlFor="new_selected_image"
                        title="New selected Image"
                      />
                      <div>
                        <img
                          src={newImagePreview}
                          alt="New Selected"
                          className="img-fluid rounded border"
                          style={{ width: "200px", height: "auto" }}
                        />
                      </div>
                    </Col>
                  )}

                  <Col md={6} className="">
                    <CommonFormLabel
                      htmlFor="gallery_image"
                      title="Uploaded Image"
                    />
                    <div className="mt-2">
                      <img
                        src={gallery.gallery_image}
                        alt="Gallery"
                        className="img-fluid rounded border"
                        style={{ width: "200px", height: "auto" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
              <hr className="my-5" />

              <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
                <CommonButton
                  title={isSubmitting ? "Updating..." : "Update"}
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommonButton
                  title="Cancel"
                  onClick={handleCancel}
                  className="btn-muted"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
