import React, { useState } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import { useNavigate } from "react-router-dom";
import Textarea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import SimpleDropdown from "../../components/simpleDropdown";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { Card, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { addTestimonial } from "../../modules/Testimonial/testimonialSlice";
import { testimonialSchema } from "../../schema/validationSchemas";

export default function Add() {
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  const ratingOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const initialEventState = {
    client_name: "",
    rating: "",
    client_message: "",
    client_image: "",
    client_address: "",
  };

  const handleModalClose = () => {
    navigate("/testimonials");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setImagePreview(URL.createObjectURL(file)); // Generate preview URL
      setFieldValue("client_image", file); // Set the file in Formik
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    await dispatch(addTestimonial(values))
      .then((res) => {
        localStorage.setItem("testimonial_notify_message", res.payload.message);
        if (res.payload && res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          navigate("/testimonials");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add testimonial!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>
      <Formik
        initialValues={initialEventState}
        validationSchema={testimonialSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleSubmit,
          values,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="bg-white p-4 rounded-2 shadow-sm"
          >
            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
              <Row>
                <Col md={6} className="">
                  <div className="mb-4">
                    <CommonFormLabel htmlFor="client_name" title="Name" />
                    <Input
                      id="client_name"
                      name="client_name"
                      type="text"
                      placeholder="Enter Client Name"
                      onChange={handleChange}
                      className={`shadow-none ${
                        errors?.client_name && touched.client_name
                          ? "error"
                          : "correct"
                      }`}
                    />
                    {touched.client_name && errors?.client_name && (
                      <ValidationFeedback title={errors?.client_name} />
                    )}
                  </div>
                </Col>

                <Col md={6} className="">
                  <SimpleDropdown
                    label="Rating"
                    name="rating"
                    value={values.rating}
                    onChange={(selectedOption) =>
                      setFieldValue("rating", selectedOption.value)
                    }
                    options={ratingOptions}
                    placeholder="Select Client Rating"
                    error={errors?.rating}
                    touched={touched.rating}
                    className={'mb-4'}
                  />
                </Col>
              </Row>

              <div className="mb-4">
                <CommonFormLabel htmlFor="client_address" title="Address" />
                <Textarea
                  id="client_address"
                  name="client_address"
                  value={values.client_address}
                  placeholder="Enter Address"
                  onChange={handleChange}
                  className={`shadow-none ${
                    errors?.client_address && touched.client_address
                      ? "error"
                      : "correct"
                  }`}
                  rows={4}
                />
                {touched.client_address && errors?.client_address && (
                  <ValidationFeedback title={errors?.client_address} />
                )}
              </div>

              <div className="mb-4">
                <CommonFormLabel htmlFor="client_message" title="Message" />
                <Textarea
                  id="client_message"
                  name="client_message"
                  value={values.client_message}
                  placeholder="Enter message"
                  onChange={handleChange}
                  className={`shadow-none ${
                    errors?.client_message && touched.client_message
                      ? "error"
                      : "correct"
                  }`}
                  rows={4}
                />
                {touched.client_message && errors?.client_message && (
                  <ValidationFeedback title={errors?.client_message} />
                )}
              </div>
            </Card>

            <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
              <h4 className="mb-4 d-flex justify-content-center">Image</h4>
              <div className="mb-4">
                <CommonFormLabel htmlFor="client_image" title="Upload Image" />
                <FileInput
                  name="client_image"
                  onChange={(file) => handleImageChange(file, setFieldValue)}
                  error={errors?.client_image}
                  touched={touched.client_image}
                />
                {touched.client_image && errors?.client_image && (
                  <ValidationFeedback title={errors?.client_image} />
                )}
                {imagePreview && (
                  <div className="mt-3">
                    <img
                      src={imagePreview}
                      alt="Blog Preview"
                      style={{
                        width: "200px",
                        height: "auto",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                )}
              </div>
            </Card>

            <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
              <CommonButton
                title={isSubmitting ? "Adding..." : "Add"}
                type="submit"
                disabled={isSubmitting}
                className="btn-primary primary-shadow"
              />
              <CommonButton
                title="Cancel"
                onClick={handleModalClose}
                className="btn-muted"
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
