import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/blog`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchBlogs = (payload) => apiHelper(API_URL, 'GET', payload);
export const createBlog = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchBlog = (blogId) => apiHelper(`${API_URL}/${blogId}`, 'GET');
export const modifyBlog = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteBlogApi = (blogId) => apiHelper(`${API_URL}/${blogId}`, 'DELETE');
export const changeStatus = (blogId) => apiHelper(`${API_URL}/change-status/${blogId}`, 'POST');
export const fetchTags = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/org/all-tags`, 'GET', payload);
