import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import TextArea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import SimpleDropdown from "../../components/simpleDropdown";
import FileInput from "../../components/fileInput";
// import CommonEditor from "../../components/commonEditor";
import { useDispatch } from "react-redux";
import {
  Modal,
  // Button,
  Toast,
  Form,
  Row,
  Col,
  // ModalHeader,
  // ModalBody,
  // ModalFooter,
  // FormLabel,
} from "react-bootstrap";
import { Formik } from "formik";
import { addCategory, getCategories, getAllCategories } from "../../modules/Category/categorySlice";
import { categorySchema } from "../../schema/validationSchemas";
import { Cancel01Icon } from "../../assets/icons";
import Editor from "../../components/commonEditor";

export default function Add({
  show,
  handleClose,
  currentPage,
  searchTerm,
  limit,
  id,
}) {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const dispatch = useDispatch();

  const token = localStorage.getItem('auth-token');

  const initialEventState = {
    parent_category_id: "",
    category_name: "",
    page_title: "",
    page_sub_title: "",
    category_video_link: "",
    category_image: "",
    image_alt: "",
    category_banner_image: "",
    category_description: "",
    brochure_file: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    default_category_description: "",
    attribute_groups: [],
  };

  const resetAttributes = () => {
    setAttributes([]);
  };

  const handleModalClose = useCallback(() => {
    handleClose();
    resetAttributes();
  }, [handleClose]);

  const fetchCategories = useCallback(async () => {
    try {
      await dispatch(getAllCategories()).then((res) => {
        if (res.payload.success) {
          if (res.payload.data.length > 0) {
            const formattedData = res.payload.data.map((item) => ({
              value: item.id, 
              label: item.category_name, 
            }));
            setCategoryOptions(formattedData);
          }
        } else {
          setCategoryOptions([]);
        }
      });
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (show) {
      fetchCategories();
    }
  }, [show, fetchCategories]);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
      setIsSubmitting(true);

      const dataToSubmit = {
        ...values,
        attribute_groups: attributes.map((attribute) => ({
          name: attribute.name,
          values: attribute.values.filter(value => value !== ''),
        })),
      };

      await dispatch(addCategory(dataToSubmit)).then((res) => {
        if(res.payload && res.payload.success) {
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          resetAttributes();
          handleClose();
          const payload = {
            page: currentPage,
            search: searchTerm,
            limit: limit,
            token
          };
          dispatch(getCategories({ payload }));
        } else {
          // setSnackbarMessage("Failed to add category!");
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      }).catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to add category!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      })
  };

  // Handle adding a new attribute
  const addAttribute = () => {
    setAttributes([...attributes, { id: attributes.length + 1, name: '', values: [''] }]);
  };

  // Handle removing an attribute
  const removeAttribute = (attributeId) => {
    setAttributes(attributes.filter(attr => attr.id !== attributeId));
  };

  // Handle adding a new value to an attribute
  const addAttributeValue = (attributeId) => {
    const updatedAttributes = attributes.map(attr =>
      attr.id === attributeId ? { ...attr, values: [...attr.values, ''] } : attr
    );
    setAttributes(updatedAttributes);
  };

  // Handle removing a value from an attribute
  const removeAttributeValue = (attributeId, valueIndex) => {
    const updatedAttributes = attributes.map(attr => {
      if (attr.id === attributeId) {
        const updatedValues = [...attr.values];
        updatedValues.splice(valueIndex, 1);
        return { ...attr, values: updatedValues };
      }
      return attr;
    });
    setAttributes(updatedAttributes);
  };

  // Handle changes to attribute names or values
  const handleAttributeChange = (attributeId, name, index = null) => (e) => {
    const updatedAttributes = attributes.map(attr => {
      if (attr.id === attributeId) {
        if (name === 'name') {
          return { ...attr, name: e.target.value };
        } else if (name === 'values' && index !== null) {
          const updatedValues = [...attr.values];
          updatedValues[index] = e.target.value;
          return { ...attr, values: updatedValues };
        }
      }
      return attr;
    });
    setAttributes(updatedAttributes);
  };

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      <Modal
        show={show}
        centered
        size="lg"
        dialogClassName="common-popup-dialog"
        contentClassName="common-popup-content"
        backdropClassName="common-popup-backdrop"
        onHide={handleModalClose}
      >
        
        <Modal.Body className="common-popup-body">
          <div className="position-absolute end-0 z-2 bg-white rounded-1 cursor-pointer d-flex align-items-center justify-content-center  common-popup-close-btn" onClick={handleModalClose}>
            <Cancel01Icon width={16} height={16} color="#BCBBC1" strokeWidth="2.5" />
          </div>
          <div className="mb-4 text-center common-popup-heading">
            <h4 className="text-capitalize mb-2 fw-medium">New Category</h4>
            <p>Add new category</p>
          </div>
          <Formik
            initialValues={initialEventState}
            validationSchema={categorySchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, handleChange, handleSubmit, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <Row>
                  <Col md={6} className="">
                    <CommonFormLabel htmlFor="category_name" title="Category Name" />
                    <Input
                      id="category_name"
                      name="category_name"
                      type="text"
                      placeholder="Enter Category Name"
                      onChange={(e) => {
                        const categoryName = e.target.value;
                        setFieldValue("category_name", categoryName);
                        setFieldValue("image_alt", categoryName);
                       
                        // Only update the image_alt if the user has not manually edited it
                        // if (!touched.image_alt || values.image_alt === values.category_name) {
                        //   setFieldValue("image_alt", categoryName);
                        // }
                      }}
                      className={`shadow-none ${errors?.category_name && touched.category_name ? 'error' : 'correct'}`}
                    />
                    {touched.category_name && errors?.category_name && (
                      <ValidationFeedback title={errors?.category_name} />
                    )}
                  </Col>

                  <Col md={6} className="">
                    <SimpleDropdown
                      label="Parent Category (Optional)"
                      name="parent_category_id"
                      value={values.parent_category_id}
                      onChange={(selectedOption) => setFieldValue("parent_category_id", selectedOption.value)}
                      options={categoryOptions}
                      placeholder="Select a Parent Category"
                      error={errors?.parent_category_id}
                      touched={touched.parent_category_id}
                      className={'mb-4'}
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="page_title" title="Page Title" />
                    <Input
                      id="page_title"
                      name="page_title"
                      type="text"
                      placeholder="Enter Page Title"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.page_title && touched.page_title ? 'error' : 'correct'}`}
                    />
                    {touched.page_title && errors?.page_title && (
                      <ValidationFeedback title={errors?.page_title} />
                    )}
                  </Col>

                  <Col md={6} className="mb-4">
                    <CommonFormLabel htmlFor="page_sub_title" title="Page Sub Title" />
                    <Input
                      id="page_sub_title"
                      name="page_sub_title"
                      type="text"
                      placeholder="Enter Page Sub Title"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.page_sub_title && touched.page_sub_title ? 'error' : 'correct'}`}
                    />
                    {touched.page_sub_title && errors?.page_sub_title && (
                      <ValidationFeedback title={errors?.page_sub_title} />
                    )}
                  </Col>
                </Row>

                  <div className="mb-4">
                    <CommonFormLabel htmlFor="category_video_link" title="Category Video Link" />
                    <Input
                      id="category_video_link"
                      name="category_video_link"
                      type="text"
                      placeholder="Enter Video Link"
                      onChange={handleChange}
                      className={`shadow-none ${errors?.category_video_link && touched.category_video_link ? 'error' : 'correct'}`}
                    />
                    {touched.category_video_link && errors?.category_video_link && (
                      <ValidationFeedback title={errors?.category_video_link} />
                    )}
                  </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="description" title="Category Description" />
                  <Editor
                    value={values.category_description}
                    onChange={(content) => setFieldValue("category_description", content)}
                  />
                  {touched.category_description && errors?.category_description && (
                    <ValidationFeedback title={errors?.category_description} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="default_category_description" title="Dynamic Description" />
                  <Editor
                    value={values.default_category_description}
                    onChange={(content) => setFieldValue("default_category_description", content)} 
                  />
                  {touched.default_category_description && errors?.default_category_description && (
                    <ValidationFeedback title={errors?.default_category_description} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="meta_title" title="Meta Title" />
                  <Input
                    id="meta_title"
                    name="meta_title"
                    type="text"
                    placeholder="Enter Meta Title"
                    onChange={handleChange}
                    className={`shadow-none ${errors?.meta_title && touched.meta_title ? 'error' : 'correct'}`}
                  />
                  {touched.meta_title && errors?.meta_title && (
                    <ValidationFeedback title={errors?.meta_title} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="meta_description" title="Meta Description" />
                  <TextArea
                      id="meta_description"
                      name="meta_description"
                      placeholder="Enter Meta Description"
                      value={values.meta_description}
                      onChange={handleChange}
                      className={`shadow-none ${errors?.meta_description && touched.meta_description ? 'error' : 'correct'}`}
                      rows={4}
                    />
                  {touched.meta_description && errors?.meta_description && (
                    <ValidationFeedback title={errors?.meta_description} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="meta_keyword" title="Meta Keywords" />
                  <TextArea
                    id="meta_keyword"
                    name="meta_keyword"
                    placeholder="Enter Meta Keywords"
                    value={values.meta_keyword}
                    onChange={handleChange}
                    className={`shadow-none ${errors?.meta_keyword && touched.meta_keyword ? 'error' : 'correct'}`}
                    rows={4}
                  />
                  {touched.meta_keyword && errors?.meta_keyword && (
                    <ValidationFeedback title={errors?.meta_keyword} />
                  )}
                </div>

                <Row>
                  <Col md={7} className="mb-4">  
                    <CommonFormLabel htmlFor="category_image" title="Upload Image" />
                    <FileInput
                      name="category_image"
                      onChange={(file) => setFieldValue("category_image", file)}
                      error={errors?.category_image}
                      touched={touched.category_image}
                    />
                    {touched.category_image && errors?.category_image && (
                      <ValidationFeedback title={errors?.category_image} />
                    )}
                  </Col>

                  <Col md={5} className="">
                    <CommonFormLabel htmlFor="image_alt" title="Image Alt" />
                    <Input
                      id="image_alt"
                      name="image_alt"
                      type="text"
                      placeholder="Enter Image Alt"
                      // onChange={handleChange}
                      onChange={(e) => {
                        setFieldValue("image_alt", e.target.value);
                      }}
                      value={values.image_alt}
                      className={`shadow-none ${errors?.image_alt && touched.image_alt ? 'error' : 'correct'}`}
                    />
                    {touched.image_alt && errors?.image_alt && (
                      <ValidationFeedback title={errors?.image_alt} />
                    )}
                  </Col>
                </Row>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="category_banner_image" title="Upload Banner Image" />
                  <FileInput
                    name="category_banner_image"
                    onChange={(file) => setFieldValue("category_banner_image", file)}
                    error={errors?.category_banner_image}
                    touched={touched.category_banner_image}
                  />
                  {touched.category_banner_image && errors?.category_banner_image && (
                    <ValidationFeedback title={errors?.category_banner_image} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="brochure_file" title="Upload Brochure" />
                  <FileInput
                    name="brochure_file"
                    onChange={(file) => setFieldValue("brochure_file", file)}
                    error={errors?.brochure_file}
                    touched={touched.brochure_file}
                  />
                  {touched.brochure_file && errors?.brochure_file && (
                    <ValidationFeedback title={errors?.brochure_file} />
                  )}
                </div>

                <hr className="my-5" />
                <div className="mb-4 text-center common-popup-heading">
                  <h4 className="text-capitalize mb-2 fw-medium">Category Attributes</h4>
                </div>
              
                <div className="mb-4">
                  {attributes.map((attribute, index) => (
                    
                    <div key={attribute.id} className="mb-4">
                      <CommonFormLabel htmlFor={`attributes ${index + 1}`} title={`Attribute ${index + 1}`} />
                      <Input
                        type="text"
                        placeholder={`Attribute ${index + 1} Name`}
                        value={attribute.name}
                        onChange={handleAttributeChange(attribute.id, 'name')}
                      />
                      <div className="mt-2">
                        {attribute.values.map((value, valueIndex) => (
                          <div key={valueIndex} className="d-flex align-items-center mb-2">
                            <Input
                              key={valueIndex}
                              type="text"
                              placeholder={`Value ${valueIndex + 1}`}
                              value={value}
                              onChange={handleAttributeChange(attribute.id, 'values', valueIndex)}
                              className="me-5"
                            />
                            <button
                              type="button"
                              className="btn btn-danger ml-2"
                              onClick={() => removeAttributeValue(attribute.id, valueIndex)}
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                        <CommonButton 
                          title="Add Value" 
                          type="button"
                          onClick={() => addAttributeValue(attribute.id)} 
                          disabled={isSubmitting} 
                          className="btn-primary primary-shadow mt-2" 
                        />
                        <button
                          type="button"
                          className="btn btn-danger mt-2 ms-2"
                          onClick={() => removeAttribute(attribute.id)}
                        >
                          Remove Attribute
                        </button>
                      </div>
                      <hr className="my-5" />
                    </div>
                  ))}
                  <CommonButton title="Add Attribute" type="button" onClick={addAttribute} disabled={isSubmitting} className="btn-primary primary-shadow" />
                </div>

                <div className="d-flex align-items-center justify-content-center gap-3 common-popup-btn mt-5">
                  <CommonButton title={isSubmitting ? "Adding..." : "Add"} type="submit" disabled={isSubmitting} className="btn-primary primary-shadow" />
                  <CommonButton title="Cancel" onClick={handleModalClose} className="btn-muted" />
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}
