import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLocations, createLocation, fetchLocation, modifyLocation, deleteLocationApi, changeStatus, changePrimaryStatus } from '../../api/locationApi';

export const getLocations = createAsyncThunk('location/getLocations', async ({payload}, { rejectWithValue }) => {
  try {
    const response = await fetchLocations(payload);
    return response.data.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addLocation = createAsyncThunk('location/addLocation', async (payload, { rejectWithValue }) => {
  try {
    const response = await createLocation(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getLocationDetails = createAsyncThunk('location/getLocationDetails', async (locationId, { rejectWithValue }) => {
  try {
    const response = await fetchLocation(locationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const updateLocation = createAsyncThunk('location/updateLocation', async (payload, { rejectWithValue }) => {
  try {
    const response = await modifyLocation(payload);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteLocation = createAsyncThunk('location/deleteLocation', async (locationId, { rejectWithValue }) => {
  try {
    const response = await deleteLocationApi(locationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const changeActiveStatus = createAsyncThunk('location/changeDefaultStatus', async (locationId, { rejectWithValue }) => {
  try {
    const response = await changeStatus(locationId);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const makePrimaryStatus = createAsyncThunk('location/makePrimaryStatus', async (locationId, { rejectWithValue }) => {
    try {
      const response = await changePrimaryStatus(locationId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    locations: [],
    isLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locations = action.payload;
      })
      .addCase(getLocations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default locationSlice.reducer;
