import { configureStore } from '@reduxjs/toolkit';
// import rootReducer from './rootReducer';
import authReducer from '../modules/Auth/authSlice';
import blogsReducer from '../modules/Blog/blogSlice';
import tagsReducer from '../modules/Tag/tagSlice';
import locationsReducer from '../modules/Pages/locationSlice';
import marketAreasReducer from '../modules/MarketArea/marketAreaSlice';
import galleriesReducer from '../modules/Gallery/gallerySlice';
import settingsReducer from '../modules//Settings/settingSlice';
import categoriesReducer from '../modules/Category/categorySlice';
// import subCategoriesReducer from '../modules/SubCategory/SubCategorySlice';
import testimonialsReducer from '../modules/Testimonial/testimonialSlice';
import teamsReducer from '../modules/Team/teamSlice';
import inquiriesReducer from '../modules/Inquiry/inquirySlice';
import productsReducer from '../modules/Product/productSlice';
import dashboardReducer from '../modules/Dashboard/dashboardSlice';

import homePageReducer from '../modules/Pages/HomePage/homePageSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    categories: categoriesReducer,
    blogs: blogsReducer,
    tags: tagsReducer,
    locations: locationsReducer,
    marketAreas: marketAreasReducer,
    galleries: galleriesReducer,
    // subcategories: subCategoriesReducer,
    testimonials: testimonialsReducer,
    teams: teamsReducer,
    inquiries: inquiriesReducer,
    products: productsReducer,
    settings: settingsReducer,
    dashboard: dashboardReducer,
    homePage: homePageReducer,
  },
  // auth: authReducer,
  // reducer: rootReducer,
  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

//   devTools: process.env.NODE_ENV !== 'production',  // Enables Redux DevTools in development
//   // You can also add middleware here if needed
});

export default store;