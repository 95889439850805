import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import Textarea from "../../components/commonTextArea";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import SimpleDropdown from "../../components/simpleDropdown";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import { Card, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import {
  updateTestimonial,
  getTestimonialDetails,
} from "../../modules/Testimonial/testimonialSlice";
import { testimonialUpdateSchema } from "../../schema/validationSchemas";

export default function Edit() {
  const { id: testimonialId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [testimonial, setTestimonial] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImagePreview, setNewImagePreview] = useState(null);

  const ratingOptions = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const fetchTestimonial = useCallback(async () => {
    setLoading(true);
    try {
      const result = await dispatch(getTestimonialDetails(testimonialId));
      setTestimonial(result.payload.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching testimonial:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, testimonialId, setLoading]);

  useEffect(() => {
    setTestimonial(null);
    if (testimonialId) {
      fetchTestimonial();
    }
  }, [testimonialId, fetchTestimonial]);

  const handleCancel = () => {
    setTestimonial(null);
    navigate("/testimonials");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setNewImagePreview(URL.createObjectURL(file));
      setFieldValue("client_image", file);
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    const updatedValues = { ...values, id: testimonialId };

    const formData = new FormData();
    formData.append("id", updatedValues.id);
    formData.append("_method", "PUT");
    formData.append("client_name", updatedValues.client_name);
    formData.append("rating", updatedValues.rating);
    formData.append("client_address", updatedValues.client_address);
    formData.append("client_message", updatedValues.client_message);
    formData.append("client_image", updatedValues.client_image);

    await dispatch(updateTestimonial(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("testimonial_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          setTestimonial(null);
          navigate("/testimonials");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(
            res.payload.message || "Failed to update testimonial!"
          );
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update testimonial!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  if (!testimonial)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Testimonial Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>
      {testimonial && (
        <Formik
          initialValues={{
            client_name: testimonial.client_name || "",
            rating: testimonial.rating || "",
            client_address: testimonial.client_address || "",
            client_message: testimonial.client_message || "",
            client_image: "",
          }}
          validationSchema={testimonialUpdateSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="bg-white shadow-lg rounded-lg p-4"
            >
              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
                <Row>
                  <Col md={6} className="">
                    <div className="mb-4">
                      <CommonFormLabel htmlFor="client_name" title="Client Name" />
                      <Input
                        id="client_name"
                        name="client_name"
                        type="text"
                        placeholder="Enter Client Name"
                        onChange={handleChange}
                        value={values.client_name}
                        className={`shadow-none ${
                          errors?.client_name && touched.client_name
                            ? "error"
                            : "correct"
                        }`}
                      />
                      {touched.client_name && errors?.client_name && (
                        <ValidationFeedback title={errors?.client_name} />
                      )}
                    </div>
                  </Col>

                  <Col md={6} className="">
                      <SimpleDropdown
                      label="Rating"
                      name="rating"
                      value={values.rating}
                      onChange={(selectedOption) =>
                        setFieldValue("rating", selectedOption.value)
                      }
                      options={ratingOptions}
                      placeholder="Select Client Rating"
                      error={errors?.rating}
                      touched={touched.rating}
                      className={'mb-4'}
                    />
                  </Col>
                </Row>
                <div className="mb-4">
                  <CommonFormLabel htmlFor="client_address" title="Address" />
                  <Textarea
                    id="client_address"
                    name="client_address"
                    placeholder="Enter address"
                    onChange={handleChange}
                    value={values.client_address}
                    className={`shadow-none ${
                      errors?.client_address && touched.client_address
                        ? "error"
                        : "correct"
                    }`}
                    rows={4}
                  />
                  {touched.client_address && errors?.client_address && (
                    <ValidationFeedback title={errors?.client_address} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel htmlFor="client_message" title="Message" />
                  <Textarea
                    id="client_message"
                    name="client_message"
                    placeholder="Enter message"
                    onChange={handleChange}
                    value={values.client_message}
                    className={`shadow-none ${
                      errors?.client_message && touched.client_message
                        ? "error"
                        : "correct"
                    }`}
                    rows={4}
                  />
                  {touched.client_message && errors?.client_message && (
                    <ValidationFeedback title={errors?.client_message} />
                  )}
                </div>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <h4 className="mb-4 d-flex justify-content-center">Image</h4>
                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="client_image"
                    title="Upload New Image"
                  />
                  <FileInput
                    name="client_image"
                    onChange={(file) => handleImageChange(file, setFieldValue)}
                    error={errors?.client_image}
                    touched={touched.client_image}
                  />
                  {touched.client_image && errors?.client_image && (
                    <ValidationFeedback title={errors?.client_image} />
                  )}
                </div>

                <Row>
                  {newImagePreview && (
                    <Col md={6} className="">
                      <CommonFormLabel
                        htmlFor="new_selected_image"
                        title="New selected Image"
                      />
                      <div>
                        <img
                          src={newImagePreview}
                          alt="New Selected"
                          className="img-fluid rounded border"
                          style={{ width: "200px", height: "auto" }}
                        />
                      </div>
                    </Col>
                  )}

                  <Col md={6} className="">
                    <CommonFormLabel
                      htmlFor="client_image"
                      title="Uploaded Image"
                    />
                    <div className="mt-2">
                      <img
                        src={testimonial.client_image}
                        alt="Testimonial"
                        className="img-fluid rounded border"
                        style={{ width: "200px", height: "auto" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>

              <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
                <CommonButton
                  title={isSubmitting ? "Updating..." : "Update"}
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommonButton
                  title="Cancel"
                  onClick={handleCancel}
                  className="btn-muted"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
