import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/tag`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchTags = (payload) => apiHelper(API_URL, 'GET', payload);
export const createTag = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchTag = (tagId) => apiHelper(`${API_URL}/${tagId}`, 'GET');
export const modifyTag = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteTagApi = (tagId) => apiHelper(`${API_URL}/${tagId}`, 'DELETE');
export const changeStatus = (tagId) => apiHelper(`${API_URL}/change-status/${tagId}`, 'POST');