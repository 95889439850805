import React, { useState, useCallback, useEffect } from "react";
import CommonFormLabel from "../../components/formLabel";
import Input from "../../components/input";
import ValidationFeedback from "../../components/validationFeedback";
import CommonButton from "../../components/commonButton";
import FileInput from "../../components/fileInput";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import { Card, Toast, Form, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { updateTeam, getTeamDetails } from "../../modules/Team/teamSlice";
import { teamUpdateSchema } from "../../schema/validationSchemas";

export default function Edit() {
  const { id: teamId } = useParams();
  const navigate = useNavigate();
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const [team, setTeam] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newImagePreview, setNewImagePreview] = useState(null);

  const fetchTeam = useCallback(async () => {
    setLoading(true);
    try {
      const result = await dispatch(getTeamDetails(teamId));
      setTeam(result.payload.data);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching team:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch, teamId, setLoading]);

  useEffect(() => {
    setTeam(null);
    if (teamId) {
      fetchTeam();
    }
  }, [teamId, fetchTeam]);

  const handleCancel = () => {
    setTeam(null);
    navigate("/teams");
  };

  const handleImageChange = (file, setFieldValue) => {
    if (file) {
      setNewImagePreview(URL.createObjectURL(file));
      setFieldValue("member_image", file);
    }
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsSubmitting(true);
    const updatedValues = { ...values, id: teamId };

    const formData = new FormData();
    formData.append("id", updatedValues.id);
    formData.append("_method", "PUT");
    formData.append("member_name", updatedValues.member_name);
    formData.append("member_position", updatedValues.member_position);
    formData.append("facebook_link", updatedValues.facebook_link);
    formData.append("instagram_link", updatedValues.instagram_link);
    formData.append("twitter_link", updatedValues.twitter_link);
    formData.append("member_image", updatedValues.member_image);

    await dispatch(updateTeam(formData))
      .then((res) => {
        if (res.payload && res.payload.success) {
          localStorage.setItem("team_notify_message", res.payload.message);
          setSnackbarMessage(res.payload.message);
          setSnackbarVariant("success");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
          setTeam(null);
          navigate("/teams");
        } else {
          setErrors(res.payload.data);
          setSnackbarMessage(res.payload.message || "Failed to update team!");
          setSnackbarVariant("danger");
          setShowSnackbar(true);
          setSubmitting(false);
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage("Failed to update team!");
        setSnackbarVariant("danger");
        setShowSnackbar(true);
        setSubmitting(false);
        setIsSubmitting(false);
      });
  };

  if (!team)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60vh" }}
      >
        <h2>Loading Team Member Details...</h2>
      </div>
    );

  return (
    <>
      <Toast
        onClose={() => setShowSnackbar(false)}
        show={showSnackbar}
        delay={3000}
        autohide
        bg={snackbarVariant}
        style={{ position: "fixed", top: 20, right: 20, zIndex: 1000 }}
      >
        <Toast.Body style={{ color: "white" }}>{snackbarMessage}</Toast.Body>
      </Toast>

      {team && (
        <Formik
          initialValues={{
            member_name: team.member_name || "",
            member_position: team.member_position || "",
            facebook_link: team.facebook_link || "",
            instagram_link: team.instagram_link || "",
            twitter_link: team.twitter_link || "",
            member_image: "",
          }}
          validationSchema={teamUpdateSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }) => (
            <Form
              onSubmit={handleSubmit}
              encType="multipart/form-data"
              className="bg-white shadow-lg rounded-lg p-4"
            >
              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <h4 className="mb-4 d-flex justify-content-center">Basic</h4>
                <div className="mb-4">
                  <CommonFormLabel htmlFor="member_name" title="Member Name" />
                  <Input
                    id="member_name"
                    name="member_name"
                    type="text"
                    placeholder="Enter Member Name"
                    onChange={handleChange}
                    value={values.member_name}
                    className={`shadow-none ${
                      errors?.member_name && touched.member_name
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.member_name && errors?.member_name && (
                    <ValidationFeedback title={errors?.member_name} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="member_position"
                    title="Member Position"
                  />
                  <Input
                    id="member_position"
                    name="member_position"
                    type="text"
                    placeholder="Enter Member Position"
                    onChange={handleChange}
                    value={values.member_position}
                    className={`shadow-none ${
                      errors?.member_position && touched.member_position
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.member_position && errors?.member_position && (
                    <ValidationFeedback title={errors?.member_position} />
                  )}
                </div>
              </Card>

              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <h4 className="mb-4 d-flex justify-content-center">
                  Social Links
                </h4>
                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="facebook_link"
                    title="Facebook Link"
                  />
                  <Input
                    id="facebook_link"
                    name="facebook_link"
                    type="text"
                    placeholder="Enter Facebook Link"
                    onChange={handleChange}
                    value={values.facebook_link}
                    className={`shadow-none ${
                      errors?.facebook_link && touched.facebook_link
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.facebook_link && errors?.facebook_link && (
                    <ValidationFeedback title={errors?.facebook_link} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="instagram_link"
                    title="Instagram Link"
                  />
                  <Input
                    id="instagram_link"
                    name="instagram_link"
                    type="text"
                    placeholder="Enter Instagram Link"
                    onChange={handleChange}
                    value={values.instagram_link}
                    className={`shadow-none ${
                      errors?.instagram_link && touched.instagram_link
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.instagram_link && errors?.instagram_link && (
                    <ValidationFeedback title={errors?.instagram_link} />
                  )}
                </div>

                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="twitter_link"
                    title="Twitter Link"
                  />
                  <Input
                    id="twitter_link"
                    name="twitter_link"
                    type="text"
                    placeholder="Enter Twitter Link"
                    onChange={handleChange}
                    value={values.twitter_link}
                    className={`shadow-none ${
                      errors?.twitter_link && touched.twitter_link
                        ? "error"
                        : "correct"
                    }`}
                  />
                  {touched.twitter_link && errors?.twitter_link && (
                    <ValidationFeedback title={errors?.twitter_link} />
                  )}
                </div>
              </Card>

              {/* Team Image */}
              <Card className="bg-white p-4 rounded-2 shadow-sm mb-4">
                <h4 className="mb-4 d-flex justify-content-center">Image</h4>
                <div className="mb-4">
                  <CommonFormLabel
                    htmlFor="member_image"
                    title="Upload New Image"
                  />
                  <FileInput
                    name="member_image"
                    onChange={(file) => handleImageChange(file, setFieldValue)}
                    error={errors?.member_image}
                    touched={touched.member_image}
                  />
                  {touched.member_image && errors?.member_image && (
                    <ValidationFeedback title={errors?.member_image} />
                  )}
                </div>

                <Row>
                  {newImagePreview && (
                    <Col md={6} className="">
                      <CommonFormLabel
                        htmlFor="new_selected_image"
                        title="New selected Image"
                      />
                      <div>
                        <img
                          src={newImagePreview}
                          alt="New Selected"
                          className="img-fluid rounded border"
                          style={{ width: "200px", height: "auto" }}
                        />
                      </div>
                    </Col>
                  )}
                  <Col md={6} className="">
                    <CommonFormLabel
                      htmlFor="member_image"
                      title="Uploaded Image"
                    />
                    <div className="mt-2">
                      <img
                        src={team.member_image}
                        alt="Team Member"
                        className="img-fluid"
                        style={{ width: "200px", height: "auto" }}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>

              <div className="d-flex align-items-center justify-content-end gap-3 common-popup-btn">
                <CommonButton
                  title={isSubmitting ? "Updating..." : "Update"}
                  type="submit"
                  disabled={isSubmitting}
                  className="btn-primary primary-shadow"
                />
                <CommonButton
                  title="Cancel"
                  onClick={handleCancel}
                  className="btn-muted"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
