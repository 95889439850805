import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/location`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchLocations = (payload) => apiHelper(API_URL, 'GET', payload);
export const createLocation = (payload) => apiHelper(API_URL, 'POST', payload, headers);
export const fetchLocation = (locationId) => apiHelper(`${API_URL}/${locationId}`, 'GET');
export const modifyLocation = (payload) => apiHelper(`${API_URL}/${payload.get('id')}`, 'POST', payload, headers);
export const deleteLocationApi = (locationId) => apiHelper(`${API_URL}/${locationId}`, 'DELETE');
export const changeStatus = (locationId) => apiHelper(`${API_URL}/change-status/${locationId}`, 'POST');
export const changePrimaryStatus = (locationId) => apiHelper(`${API_URL}/change-primary-status/${locationId}`, 'POST');