import { apiHelper } from '../utils/apiHelper';

const API_URL = `${process.env.REACT_APP_API_URL}/api/org/settings`;
const UPDATE_API_URL = `${process.env.REACT_APP_API_URL}/api/org`;

const headers = {
  "Content-Type": "multipart/form-data",
  "Accept": "application/json"
};

export const fetchSettings = (payload) => apiHelper(API_URL, 'GET', payload);
export const modifySettings = (payload) => apiHelper(`${UPDATE_API_URL}/update-settings`, 'POST', payload, headers);
export const modifyBasicInformation = (payload) => apiHelper(`${UPDATE_API_URL}/update-basic-information`, 'POST', payload, headers);
export const modifyCompanyInformation = (payload) => apiHelper(`${UPDATE_API_URL}/update-company-information`, 'POST', payload, headers);
export const modifySocialLinks = (payload) => apiHelper(`${UPDATE_API_URL}/update-social-link`, 'POST', payload, headers);
export const modifyWebsiteConfiguration = (payload) => apiHelper(`${UPDATE_API_URL}/update-website-configuration`, 'POST', payload, headers);
export const fetchCountries = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/countries`, 'GET', payload);
export const fetchStates = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/states`, 'POST', payload);
export const fetchCities = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/cities`, 'POST', payload);
export const fetchLegalFirms = (payload) => apiHelper(`${process.env.REACT_APP_API_URL}/api/legal-firms`, 'GET', payload);
export const modifyIcons = (payload) => apiHelper(`${UPDATE_API_URL}/update-icons`, 'POST', payload, headers);
export const modifyGoogleTag = (payload) => apiHelper(`${UPDATE_API_URL}/update-google-tag`, 'POST', payload, headers);
export const modifyMsValidate = (payload) => apiHelper(`${UPDATE_API_URL}/update-ms-validate`, 'POST', payload, headers);
export const modifyCustomerCareData = (payload) => apiHelper(`${UPDATE_API_URL}/update-customer-care-data`, 'POST', payload, headers);