import React, { useState } from "react";
import Select, { components } from "react-select";
import ValidationFeedback from "../validationFeedback";
// import { toast } from "react-toastify";

const MultiSelectDropdown = ({
  label,
  name,
  value,
  onChange,
  options,
  placeholder = "Select options",
  error,
  touched,
  className = "",
  onCreateTag,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      event.preventDefault(); 

      const newTagName = inputValue.trim();
      const existingTag = options.find(
        (option) => option.label.toLowerCase() === newTagName.toLowerCase()
      );

      if (!existingTag) {
        onCreateTag(newTagName)
          .then((newTag) => {
            options.push(newTag);
            onChange([...value, newTag.value]);
            setInputValue("");
          })
          .catch((error) => {
            console.error("Error creating tag:", error);
            // toast.error("Failed to create new tag.");
          });
      } else {
        // toast.info("Tag already exists.");
      }
    }
  };

  return (
    <div className={`${className}`}>
      {label && <label htmlFor={name} className="mb-1 common-form-label">{label}</label>}
      <Select
        name={name}
        value={options.filter((option) => value.includes(option.value))}
        options={options}
        isMulti
        onInputChange={(newValue) => setInputValue(newValue)}
        inputValue={inputValue}
        onChange={(selectedOptions) =>
          onChange(selectedOptions ? selectedOptions.map((option) => option.value) : [])
        }
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        classNamePrefix="custom-dropdown"
        isClearable={true}
        components={{
          DropdownIndicator: components.DropdownIndicator,
          ClearIndicator: components.ClearIndicator,
        }}
      />
      {touched && error ? <ValidationFeedback title={error} /> : null}
    </div>
  );
};

export default MultiSelectDropdown;
